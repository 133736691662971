import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from 'react';

import { MainFooter } from '../Common';
import './SearchPage.css'
import { searchKeyword } from "../../reducers/search";

const SearchPage = () => {
    return (
        <>
            <div className="SearchMain d-flex">
                <SearchMainLeft />
                <SearchMainRight />
            </div>

            <MainFooter />
        </>
    )
}


export default SearchPage;

const SearchMainLeft = () => {


    const courseType = ['개발 · 프로그래밍', '보안 · 네트워크', '데이터 사이언스', '게임 개발', '크리에이티브', '직무 · 마케팅', '학문 · 외국어',
        '커리어', '교양'];

    return (
        <>
            <ul class="nav flex-column">
                <li class="nav-item left-nav-bar">
                    <Link to="#">전체강의</Link>
                </li>
                {
                    courseType.map((data, index) => {
                        return (
                            <li class="nav-item left-nav-bar" key={index}>
                                <Link to="#">{data}</Link>
                                <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "12px" }} />
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}

const SearchMainRight = () => {

    let {Keyword} = useParams();

    let dispatch = useDispatch();
    let searchResultState = useSelector((state) => { return state.searchReducer });

    // console.log(searchResultState);

    return (
        <div className='search-main-right'>
            <div className='search-header'>
                <div>전체 강의</div>
                <form className="d-flex search-header-form" action=".">
                    <div className="search-header-form-input-div">
                        <input value={Keyword} type="search" placeholder="전체 강의 검색" className="" required="" />
                    </div>
                    <button type="button" class="">
                        검색
                    </button>
                </form>
            </div>
            <div className='search-skill-bar'>
                <div className='search-header-form-div'>
                    <form className="d-flex search-header-form" action=".">
                        <div className="search-header-form-input-div">
                            <input style={{ width: "123px" }} value="기술 검색" type="search" placeholder="전체 강의 검색" required="" />
                        </div>
                        <button type="button" className="">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                </div>
                <button class="courses__skill-tag">Flutter</button>
                <button class="courses__skill-tag">Android</button>
                <button class="courses__skill-tag">iOS</button>
                <button class="courses__skill-tag">Django</button>
                <button class="courses__skill-tag">Spring</button>
                <button class="courses__skill-tag">블록체인</button>
                <button class="courses__skill-tag">클론코딩</button>
                <button class="courses__skill-tag">Python</button>
                <button class="courses__skill-tag">React Native</button>
                <button class="courses__skill-tag">Java</button>
            </div>
            <div className='check-btn-back-div'>
                <div className='check-btn-div'>
                    <button class="check-btn-div-price">전체</button>
                    <button class="check-btn-div-price">무료</button>
                    <button class="check-btn-div-price">유료</button>
                    <button class="check-btn-div-score">할인중</button>
                    <button class="check-btn-div-score">입문</button>
                    <button class="check-btn-div-score">초급</button>
                    <button class="check-btn-div-score">중급이상</button>
                </div>
                <div className=''>
                    <select class="check-btn-select" >
                        <option selected="" value="search">정확도순</option>
                        <option value="seq">추천순</option>
                        <option value="popular">인기순</option>
                        <option value="recent">최신순</option>
                        <option value="rating">평점순</option>
                        <option value="famous">좋아요순</option>
                    </select>
                </div>
            </div>
            <div className='search-result-cards-back'>
                <div className='search-result-cards-div'>
                    {
                        searchResultState.map((data)=>{
                            return(
                                <div className='search-result-card' key={data.index}>
                                    <div className="card-img">
                                        <img loading="lazy" src={data.img} data-src={data.img} />
                                        {
                                            data.discount !== false?
                                            <div className="card-ribbon">
                                                <i className="fas fa-badge-percent"></i>
                                                {data.discount}
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="card-text">
                                        <div className="course_title">{data.title}</div>
                                        <div className="instructor">{data.name}</div>
                                        <div className="rating">
                                            {
                                                data.star !== false?
                                                <div className="rating_star">
                                                    <div className="star_solid" style={{ whiteSpace: "nowrap", color: "#fdcc11" }}>
                                                        {
                                                            data.star.map(function(starData,index){
                                                                if (starData == true){
                                                                    return ( <svg key={index} aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" data-value="1"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg> )
                                                                } else {
                                                                    return ( <svg key={index} aria-hidden="true" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" data-value="5"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg> )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {
                                                data.reviewCnt !== false? <span className="review_cnt">{data.reviewCnt}</span> : null
                                            }
                                        </div>
                                            {
                                                data.price === "무료"? <div className="price">무료</div> : <div className="price"><span className="del-text">{data.delPrice}</span> {data.price}</div>
                                            }
                                        <div className="tags">
                                            {
                                                data.label.studentCnt !== false ? <span className="tag" style={{ backgroundColor: "hsl(321,63%,90%)" }} >{data.label.studentCnt}</span> : null
                                            }
                                            {
                                                data.label.update !== false ?  <span className="tag" style={{ backgroundColor: "hsl(182,75%,94%)" }} >업데이트</span> : null
                                            }
                                            {
                                                data.label.new !== false ? <span className="tag " style={{ backgroundColor: "hsl(182,75%,94%)" }} >새강의</span> : null
                                            }
                                            {
                                                data.label.discount !== false? <span className="tag " style={{ backgroundColor: "hsl(1,100%,89%)" }}>할인중</span> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
