/* eslint-disable */
import './App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronLeft, faPause, faPlay, faChevronRight, faChevronDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTwitter, faYoutube, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import {Routes, Route, Link } from 'react-router-dom'

import MainPage from './pages/MainPage/MainPage';
import DetailPage from './pages/DetailPage/DetailPage';
import MentorPage from './pages/MentorPage/MentorPage';
import CartPage from './pages/CartPage/CartPage';
import SearchPage from './pages/SearchPage/SearchPage';
import {MainSection, MainHeader, LonginModal} from './pages/Common';

function App() {
  return (
    <div className="App">

      {/* login */}
      <LonginModal/>

      {/* section */}
      <MainSection/>

      {/* header */}
      <MainHeader/>

      <Routes>
        <Route path='/' element={ <MainPage/> } />
        <Route path='/mentor' element={ <MentorPage/> } />
        <Route path='/detail' element={ <DetailPage/> } />
        <Route path='/cart' element={<CartPage/>} />
        <Route path='/Search/:Keyword' element={<SearchPage/>} />
      </Routes>

    </div>
  );
}






export default App;





