import { FreeCourseData, WelcomeCourseData, RecentCourseData } from "../pages/MainPage/MainPageData"

const CourseDataArray = [...FreeCourseData, ...WelcomeCourseData, ...RecentCourseData]
const initialState = []

const searchReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SEARCH': {
            // console.log(action.Keyword);
            let SearchState = CourseDataArray.filter((data) => {
                let data_arr = [...data.hashTag]
                data_arr.push(data.title)
                // console.log(typeof data_arr)
                return data_arr.includes(action.Keyword)
            })

            // console.log(SearchState)
            return SearchState
        }
        default: {
            return state
        }
    }
}


export default searchReducer;

export const searchKeyword = (Keyword) => {
    return (
        {
            type: 'SEARCH',
            Keyword: Keyword
        }
    )
}




