import { FreeCourseData, WelcomeCourseData, RecentCourseData } from "../pages/MainPage/MainPageData"

const CourseDataArray = [...FreeCourseData, ...WelcomeCourseData, ...RecentCourseData]
const initialState = { cart :[], btnStyle: Array(CourseDataArray.length).fill(false) }

const cartReducer = (state = initialState, action) => {

    switch(action.type){
        case 'ADD':{
            let newState = {...state}  // 현재 state deep copy
            let cartIndex = newState.cart.findIndex( (data)=>{ return data.index === action.IndexData } ) // 같은 index를 가진 데이터가 있는지 확인

            if (cartIndex===-1){  // state array에 같은 index가 없다면
                newState.cart.push(CourseDataArray[action.IndexData-1])  // 추가
            } else{  // 있다면 삭제
                newState.cart.splice( cartIndex, 1 )
            }

            if ( newState.btnStyle[action.IndexData-1] === false ){  // 장바구니 버튼 색변경
                newState.btnStyle[action.IndexData-1] = true 
            } else if( newState.btnStyle[action.IndexData-1] === true ){
                newState.btnStyle[action.IndexData-1] = false
            }

            console.log(newState);
            return newState
        }
        default:{
            return state
        }
    }
}

export default cartReducer;

export const addCart = (IndexData)=>{
    return (
        {
            type : 'ADD',
            IndexData : IndexData
        }
    )
}




