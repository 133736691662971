/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronLeft, faPause, faPlay, faChevronRight, faChevronDown, faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

import CardData from "./MentorPageData";
import { MainFooter } from "../Common";
import './MentorPage.css'

function MentorPage() {

    let [CardDataState, setCardDataState ] = useState(CardData);
    let [FilterCardState, setFilterCardState] = useState([]);

    // 체크박스 어레이, 체크박스 체크시 어레이 변화 함수
    let [CheckBoxArr, setCheckBoxArr] = useState([]);
    const CheckBoxfunction = (type)=>{
        let newCheckBoxArr = [...CheckBoxArr]
        if ( newCheckBoxArr.includes(type) ){
            let filtered = newCheckBoxArr.filter((element) => element !== type);
            setCheckBoxArr(filtered)
            // console.log("filtered",filtered)
        } else{
            newCheckBoxArr.push(type)
            setCheckBoxArr(newCheckBoxArr)
            // console.log("new",newCheckBoxArr)
        }
    }

    // checkboxarray가 변할때 그려주는 card 변화 시키기
    useEffect(()=>{
        if( CheckBoxArr.length >= 1 ){
            let filteredCard = CardDataState.filter((element) =>  CheckBoxArr.includes(element['type']))
            setFilterCardState( filteredCard )
            console.log(filteredCard)
        } else{
            setFilterCardState( CardDataState )
        }
    },[CheckBoxArr]);


    return (
        <>
            <div className="mentor-main-back">
                <div className="mentor-header">
                    <a href="./">
                        <div className="mentor-header-container">
                            <div className="mentor-header-img"></div>
                            <h1>멘토링</h1>
                            <p>
                                업계 선배들 혹은 동료들과 인사이트를 나눠 보세요.<br />
                                더 빨리, 더 멀리 갈 수 있어요.
                            </p>
                            <span className="heading_more">멘토 지원하기  <svg height="20" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg"><path fill="#212529" clip-rule="evenodd" d="m6.22 3.22c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06l-4.25 4.25c-.293.293-.767.293-1.06 0s-.293-.767 0-1.06l3.72-3.72-3.72-3.72c-.293-.293-.293-.767 0-1.06z" fill-rule="evenodd"></path></svg></span>
                        </div>
                    </a>
                </div>

                <div className="mentor-main">
                    <div className="mentor-left-panel">
                        <div className="mentor-left-panel__content">

                            <button type="button" class="filter_btn">
                            필터
                            </button>

                            <div className="mentor-left-panel__content-search">
                                <div className="control" style={{marginRight:"-1px"}}>
                                    <input className="" type="text" value=""/>
                                </div>
                                <div className="control">
                                    <button type="button" className="">검색</button>
                                </div>
                            </div>

                            <div className="left-panel-sort">
                                <select data-type="order">
                                    <option value="common">기본순</option>
                                    <option value="latest">최근신청순</option>
                                    <option value="recent">신규멘토순</option>
                                    <option value="count">인기순</option>
                                </select>
                            </div>

                            <nav className="left-panel-checkbox">
                                <div className="">
                                    <div className="left-panel-checkbox-header">분야별<svg style={{marginLeft:"4px",transform:"rotate(90deg)"}} height="20" viewBox="0 0 16 16" width="20" xmlns="http://www.w3.org/2000/svg"><path fill="#212529" clip-rule="evenodd" d="m6.22 3.22c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06l-4.25 4.25c-.293.293-.767.293-1.06 0s-.293-.767 0-1.06l3.72-3.72-3.72-3.72c-.293-.293-.293-.767 0-1.06z" fill-rule="evenodd"></path></svg></div>
                                    <div className="left-panel-checkbox-body">
                                        <div className="left-panel-checkbox-body-content">
                                            <div className="">
                                                <input className="check-dev" type="checkbox" data-type="terms" id="5" value="5" 
                                                    onClick={()=>{
                                                        CheckBoxfunction('dev')
                                                    }}/>
                                                <label for="5" className="left">
                                                    개발 · 프로그래밍
                                                </label>
                                            </div>
                                        </div>
                                        <div className="left-panel-checkbox-body-content">
                                            <div className="">
                                                <input className="check-network" type="checkbox" data-type="terms" id="492" value="492" 
                                                onClick={()=>{
                                                    CheckBoxfunction('network')
                                                }}/>
                                                <label for="492" className="left">
                                                    보안 · 네트워크
                                                </label>
                                            </div>
                                        </div>
                                        <div className="left-panel-checkbox-body-content">
                                            <div className="">
                                                <input className="check-data" type="checkbox" data-type="terms" id="9" value="9" 
                                                onClick={()=>{
                                                    CheckBoxfunction('data')
                                                }}/>
                                                <label for="9" className="left">
                                                    데이터 사이언스
                                                </label>
                                            </div>
                                        </div>
                                        <div className="left-panel-checkbox-body-content">
                                            <div className="">
                                                <input className="check-game" type="checkbox" data-type="terms" id="39306" value="39306" 
                                                onClick={()=>{
                                                    CheckBoxfunction('game')
                                                }}/>
                                                <label for="39306" className="left">
                                                    게임 개발
                                                </label>
                                            </div>
                                        </div>
                                        <div className="left-panel-checkbox-body-content">
                                            <div className="">
                                                <input className="check-creative" type="checkbox" data-type="terms" id="22" value="22" />
                                                <label for="22" className="left">
                                                    크리에이티브
                                                </label>
                                            </div>
                                        </div>
                                        <div className="left-panel-checkbox-body-content">
                                            <div className="">
                                                <input className="check-marketing" type="checkbox" data-type="terms" id="33" value="33" />
                                                <label for="33" className="left">
                                                    직무 · 마케팅
                                                </label>
                                            </div>
                                        </div>
                                        <div className="left-panel-checkbox-body-content">
                                            <div className="">
                                                <input className="check-foreign" type="checkbox" data-type="terms" id="493" value="493" />
                                                <label for="493" className="left">
                                                    학문 · 외국어
                                                </label>
                                            </div>
                                        </div>
                                        <div className="left-panel-checkbox-body-content" style={{marginRight:"60px"}}>
                                            <div className="">
                                                <input className="check-carrer" type="checkbox" data-type="terms" id="494" value="494" />
                                                <label for="494" className="left">
                                                    커리어
                                                </label>
                                            </div>
                                        </div>
                                        <div className="left-panel-checkbox-body-content">
                                            <div className="">
                                                <input className="check-study" type="checkbox" data-type="terms" id="666" value="666" />
                                                <label for="666" className="left">
                                                    교양
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            <a href="./" className="filter_reset"><i className="far fa-sync-alt"></i>&nbsp;&nbsp;필터 초기화</a>
                        </div>
                    </div>

                    <div className="mentor-right-panel">
                        <div className="mentor-cards">
                            <MentorCard FilterCardState={FilterCardState} />
                        </div>
                        <nav className="right-panel-paginate" role="navigation" aria-label="pagination">
                            <ul className="pages">
                                <li><button className="page e-pagination  active" data-page="1" aria-label="1페이지로 이동">1</button></li>
                                <li><button className="page e-pagination  " data-page="2" aria-label="2페이지로 이동">2</button></li>
                                <li><button className="page e-pagination  " data-page="3" aria-label="3페이지로 이동">3</button></li>
                                <li><button className="page e-pagination  " data-page="4" aria-label="4페이지로 이동">4</button></li>
                                <li><button className="page e-pagination  " data-page="5" aria-label="5페이지로 이동">5</button></li>
                                <li><button className="page e-pagination  " data-page="6" aria-label="6페이지로 이동">6</button></li>
                                <li><button className="page e-pagination  " data-page="7" aria-label="7페이지로 이동">7</button></li>

                                <li><button className="page e-pagination  next" data-page="2" aria-label="2페이지로 이동">다음</button></li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>

            <MainFooter/>

        </>
    )
}




const MentorCard = ( {FilterCardState} ) =>{
    return(
        FilterCardState.map((data, i)=>{
            return(
                <>
                    <section class="mentor-card" key={data.index}>
                        <div class="mentor-card__top">
                            <h3 class="mentor-card__title">{data['title']}</h3>
                            <div class="mentor-card__job-wrapper">
                            <dl class="mentor-card__job-info-list">
                                {
                                    data['jobInfo'].length === 2 ?
                                    <>
                                        <div><dt>직무</dt><dd>{data['jobInfo'][0]}</dd></div>
                                        <div><dt>경력</dt><dd>{data['jobInfo'][1]}</dd></div>
                                    </>
                                    : <>
                                        <div><dt>직무</dt><dd>{data['jobInfo'][0]}</dd></div>
                                        <div><dt>경력</dt><dd>{data['jobInfo'][1]}</dd></div>
                                        <div><dt>현직</dt><dd>{data['jobInfo'][2]}</dd></div>
                                    </>
                                }
                            </dl>
                            <figure class="mentor-card__thumbnail">
                                <img src={data['img']} alt="멘토 이미지" loading="lazy"/>
                            </figure>
                            </div>
                        </div>
                        <div class="mentor-card__bottom">
                            <div>
                            <a href="/" target="_blank" rel="noopener noreferrer" class="mentor-card__name">{data['name']}</a>
        
                            {
                                data['star'] !== ''?
                                <button class="mentor-card__rating e-show-reviews">
                                    <FontAwesomeIcon icon={faStar} className="far fa-chevron-right" style={{marginRight:'3px',fontSize:'14px'}} />
                                    <span class="rating-number" style={{fontSize:'14px'}} ><b> {data['star']} </b></span>
                                    <FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" style={{marginLeft:'3px',fontSize:'12px'}} />
                                </button>
                                : null
                            }
                            </div>
                        </div>
                    </section>
                </>
            )
        })
    )
}




export default MentorPage;



