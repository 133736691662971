let CardData = [
    {
        index : 1,
        type : 'dev',
        title:'신입~주니어 개발자 취직, 이력서&프론트엔드 상담 및 고민, 커리어 및 로드맵 설계',
        jobInfo : ['프론트엔드/웹퍼블리셔','미들(4~8년)'],
        img : 'https://cdn.inflearn.com/public/users/thumbnails/36294/c14ef375-cddf-4d75-82d9-055109c79eb6',
        name : '장현석',
        star: '5.0'
    },
    {
        index : 2,
        type : 'network',
        title:'개발자 취업, 기술 면접 준비 / 앱개발 방법 / 기술사',
        jobInfo : ['안드로이드 개발자','Lead 레벨'],
        img : "https://cdn.inflearn.com/public/users/thumbnails/491366/311487a6-1734-427c-ab8c-5641bf12c403",
        name : '기술노트with알렉',
        star: '5.0'
    },
    {
        index : 3,
        type : 'data',
        title:'비전공자? 학부 출신? Data Scientist / ML Enginner, 당신도 할 수 있습니다!',
        jobInfo : ['데이터 사이언티스트','주니어 (1~3년)','카카오 계열사'],
        img : "https://cdn.inflearn.com/public/users/thumbnails/231664/b63ef4f9-7bf5-4dde-829c-40e8b1902a86",
        name : '밑바닥개발자',
        star: '5.0'
    },
    {
        index : 4,
        type : 'game',
        title:'게임서버개발 멘토링.. (취업 준비생 대상)',
        jobInfo : ['게임 서버 개발자','신입 (1년이하)'],
        img : "https://cdn.inflearn.com/public/main/profile/default_profile.png",
        name : 'gameServerMaster',
        star : ''
    },
    {
        index : 5,
        type : 'network',
        title:'개발자 취업, 기술 면접 준비 / 앱개발 방법 / 기술사',
        jobInfo : ['안드로이드 개발자','Lead 레벨'],
        img : "https://cdn.inflearn.com/public/users/thumbnails/491366/311487a6-1734-427c-ab8c-5641bf12c403",
        name : '기술노트with알렉',
        star: '5.0'
    },
    {
        index : 6,
        type : 'data',
        title:'비전공자? 학부 출신? Data Scientist / ML Enginner, 당신도 할 수 있습니다!',
        jobInfo : ['데이터 사이언티스트','주니어 (1~3년)','카카오 계열사'],
        img : "https://cdn.inflearn.com/public/users/thumbnails/231664/b63ef4f9-7bf5-4dde-829c-40e8b1902a86",
        name : '밑바닥개발자',
        star: '5.0'
    },
    {
        index : 7,
        type : 'game',
        title:'게임서버개발 멘토링.. (취업 준비생 대상)',
        jobInfo : ['게임 서버 개발자','신입 (1년이하)'],
        img : "https://cdn.inflearn.com/public/main/profile/default_profile.png",
        name : 'gameServerMaster',
        star : ''
    },
    {
        index : 8,
        type : 'dev',
        title:'신입~주니어 개발자 취직, 이력서&프론트엔드 상담 및 고민, 커리어 및 로드맵 설계',
        jobInfo : ['프론트엔드/웹퍼블리셔','미들(4~8년)'],
        img : 'https://cdn.inflearn.com/public/users/thumbnails/36294/c14ef375-cddf-4d75-82d9-055109c79eb6',
        name : '장현석',
        star: '5.0'
    },
    {
        index : 9,
        type : 'data',
        title:'비전공자? 학부 출신? Data Scientist / ML Enginner, 당신도 할 수 있습니다!',
        jobInfo : ['데이터 사이언티스트','주니어 (1~3년)','카카오 계열사'],
        img : "https://cdn.inflearn.com/public/users/thumbnails/231664/b63ef4f9-7bf5-4dde-829c-40e8b1902a86",
        name : '밑바닥개발자',
        star: '5.0'
    },
    {
        index : 10,
        type : 'game',
        title:'게임서버개발 멘토링.. (취업 준비생 대상)',
        jobInfo : ['게임 서버 개발자','신입 (1년이하)'],
        img : "https://cdn.inflearn.com/public/main/profile/default_profile.png",
        name : 'gameServerMaster',
        star : ''
    },
    {
        index : 11,
        type : 'dev',
        title:'신입~주니어 개발자 취직, 이력서&프론트엔드 상담 및 고민, 커리어 및 로드맵 설계',
        jobInfo : ['프론트엔드/웹퍼블리셔','미들(4~8년)'],
        img : 'https://cdn.inflearn.com/public/users/thumbnails/36294/c14ef375-cddf-4d75-82d9-055109c79eb6',
        name : '장현석',
        star: '5.0'
    },
    {
        index : 12,
        type : 'network',
        title:'개발자 취업, 기술 면접 준비 / 앱개발 방법 / 기술사',
        jobInfo : ['안드로이드 개발자','Lead 레벨'],
        img : "https://cdn.inflearn.com/public/users/thumbnails/491366/311487a6-1734-427c-ab8c-5641bf12c403",
        name : '기술노트with알렉',
        star: '5.0'
    },
    {
        index : 13,
        type : 'game',
        title:'게임서버개발 멘토링.. (취업 준비생 대상)',
        jobInfo : ['게임 서버 개발자','신입 (1년이하)'],
        img : "https://cdn.inflearn.com/public/main/profile/default_profile.png",
        name : 'gameServerMaster',
        star : ''
    },
    {
        index : 14,
        type : 'dev',
        title:'신입~주니어 개발자 취직, 이력서&프론트엔드 상담 및 고민, 커리어 및 로드맵 설계',
        jobInfo : ['프론트엔드/웹퍼블리셔','미들(4~8년)'],
        img : 'https://cdn.inflearn.com/public/users/thumbnails/36294/c14ef375-cddf-4d75-82d9-055109c79eb6',
        name : '장현석',
        star: '5.0'
    },
    {
        index : 15,
        type : 'network',
        title:'개발자 취업, 기술 면접 준비 / 앱개발 방법 / 기술사',
        jobInfo : ['안드로이드 개발자','Lead 레벨'],
        img : "https://cdn.inflearn.com/public/users/thumbnails/491366/311487a6-1734-427c-ab8c-5641bf12c403",
        name : '기술노트with알렉',
        star: '5.0'
    },
    {
        index : 16,
        type : 'data',
        title:'비전공자? 학부 출신? Data Scientist / ML Enginner, 당신도 할 수 있습니다!',
        jobInfo : ['데이터 사이언티스트','주니어 (1~3년)','카카오 계열사'],
        img : "https://cdn.inflearn.com/public/users/thumbnails/231664/b63ef4f9-7bf5-4dde-829c-40e8b1902a86",
        name : '밑바닥개발자',
        star: '5.0'
    },
    // {
    //     type : '',
    //     title:'',
    //     jobInfo : '',
    //     img : '',
    //     name : '',
    //     star : ''
    // }
]

export default CardData;






