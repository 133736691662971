


const FreeCourseData = [
    {  
        index : 1,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/course-324063-cover/3c856a69-e213-4394-8317-1469386b82cc",
        discount : false,
        title : "웹 게임을 만들며 배우는 React",
        name : "조현영",
        star :[true,true,true,true,true],
        reviewCnt : "(255)",
        delPrice : "무료",
        price: "무료",
        label : {update:true, new:false, studentCnt :"+10000명",discount:false },
        hashTag : ["리액트","react","web","game"],
    },
    {  
        index : 2,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329667/cover/adc25cdf-e5ed-4724-ad8b-cd56724ba306/인프런_DB_대표이미지.png",
        discount : false,
        title : "시니어 백엔드 개발자가 알려주는 데이터베이스 개론 & SQL",
        name : "쉬운코드",
        star :[true,true,true,true,true],
        reviewCnt : "(9)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+700명",discount:false },
        hashTag : ["backend","DB","db","data","sql"],
    },
    {  
        index : 3,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329442/cover/9b564bc1-433b-4a85-a2f2-147bf198bdc3/329442-eng.png",
        discount : false,
        title : "시작해보세요! 당신의 첫 지식공유",
        name : "인프런",
        star :[true,true,true,true,true],
        reviewCnt : "(8)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+200명",discount:false },
        hashTag : [],
    },
    {  
        index : 4,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/328001/cover/e11c6e6a-14c6-494a-954d-5fc625eb89a6/328001-eng.png",
        discount : false,
        title : "개발하는 정대리 스위프트 기초 문법",
        name : "개발하는 정대리",
        star :[true,true,true,true,true],
        reviewCnt : "(27)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+2500명",discount:false },
        hashTag : ["swift"],
    },
    {  
        index : 5,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/326283/cover/ef7611ae-fcad-4c35-9d2e-fadd0765b28e/graphql-apollo-thumb-2 복사.png",
        discount : false,
        title : "얄팍한 GraphQL과 Apollo",
        name : "얄팍한 코딩사전",
        star :[true,true,true,true,true],
        reviewCnt : "(115)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+2100명",discount:false },
        hashTag : ["그래프QL","아폴로"],
    },
    {  
        index : 6,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/course-324063-cover/3c856a69-e213-4394-8317-1469386b82cc",
        discount : false,
        title : "웹 게임을 만들며 배우는 React",
        name : "조현영",
        star :[true,true,true,true,true],
        reviewCnt : "(255)",
        delPrice : "무료",
        price: "무료",
        label : {update:true, new:false, studentCnt :"+10000명",discount:false },
        hashTag : ["리액트","react","web","game"],
    },
    {  
        index : 7,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329667/cover/adc25cdf-e5ed-4724-ad8b-cd56724ba306/인프런_DB_대표이미지.png",
        discount : false,
        title : "시니어 백엔드 개발자가 알려주는 데이터베이스 개론 & SQL",
        name : "쉬운코드",
        star :[true,true,true,true,true],
        reviewCnt : "(9)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+700명",discount:false },
        hashTag : ["backend","DB","db","data","sql"],
    },
    {  
        index : 8,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329442/cover/9b564bc1-433b-4a85-a2f2-147bf198bdc3/329442-eng.png",
        discount : false,
        title : "시작해보세요! 당신의 첫 지식공유",
        name : "인프런",
        star :[true,true,true,true,true],
        reviewCnt : "(8)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+200명",discount:false },
        hashTag : [],
    },
    {  
        index : 9,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/328001/cover/e11c6e6a-14c6-494a-954d-5fc625eb89a6/328001-eng.png",
        discount : false,
        title : "개발하는 정대리 스위프트 기초 문법",
        name : "개발하는 정대리",
        star :[true,true,true,true,true],
        reviewCnt : "(27)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+2500명",discount:false },
        hashTag : ["swift"],
    },
    {  
        index : 10,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/326283/cover/ef7611ae-fcad-4c35-9d2e-fadd0765b28e/graphql-apollo-thumb-2 복사.png",
        discount : false,
        title : "얄팍한 GraphQL과 Apollo",
        name : "얄팍한 코딩사전",
        star :[true,true,true,true,true],
        reviewCnt : "(115)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+2100명",discount:false },
        hashTag : ["그래프QL","아폴로"],
    },
    {  
        index : 11,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/course-324063-cover/3c856a69-e213-4394-8317-1469386b82cc",
        discount : false,
        title : "웹 게임을 만들며 배우는 React",
        name : "조현영",
        star :[true,true,true,true,true],
        reviewCnt : "(255)",
        delPrice : "무료",
        price: "무료",
        label : {update:true, new:false, studentCnt :"+10000명",discount:false },
        hashTag : ["리액트","react","web","game"],
    },
    {  
        index : 12,
        link : "./",
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329667/cover/adc25cdf-e5ed-4724-ad8b-cd56724ba306/인프런_DB_대표이미지.png",
        discount : false,
        title : "시니어 백엔드 개발자가 알려주는 데이터베이스 개론 & SQL",
        name : "쉬운코드",
        star :[true,true,true,true,true],
        reviewCnt : "(9)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+700명",discount:false },
        hashTag : ["backend","DB","db","data","sql"],
    },
]
const WelcomeCourseData =[
    {  
        index : 13,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/329986/cover/15670e82-a5c6-4fc4-801a-993dfc284627/썸네일_인프런_20221215(3).png",
        discount : "30% 할인",
        title : "나도코딩의 자바 기본편 - 풀코스 (20시간)",
        name : "나도코딩",
        star :[true,true,true,false,false],
        reviewCnt : false,
        delPrice : "₩71,500",
        price: "₩50,050",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 14,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star :[false,false,false,false,false],
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 15,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/327096/cover/fa1cbc3c-c0e8-4a3d-9f2a-1150b67f6457/327096-eng.png",
        discount : "30% 할인 (D-9)",
        title : "[왕초보편] 앱 8개를 만들면서 배우는 안드로이드 코틀린(Android Kotlin)",
        name : "개복치개발자",
        star :[true,true,true,true,true],
        reviewCnt : "(100)",
        delPrice : "₩24,200",
        price: "₩16,940",
        label : {update:false, new:false, studentCnt :"+1100명", discount:true },
        hashTag : [],
    },
    {  
        index : 16,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/wp-content/uploads/ktw_algorithm3.jpg",
        discount : "30% 할인 (D-9)",
        title : "it 취업을 위한 알고리즘 문제풀이 입문 (with C/C++) : 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(119)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+2900명", discount:true },
        hashTag : [],
    },
    {  
        index : 17,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/326750/cover/d7815167-2a01-42c1-aacf-bbd32a3705bc/326750-eng.png",
        discount : "30% 할인 (D-9)",
        title : "자바(Java) 알고리즘 문제풀이 입문: 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(168)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+4200명", discount:true },
        hashTag : [],
    },
    {  
        index : 18,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/329986/cover/15670e82-a5c6-4fc4-801a-993dfc284627/썸네일_인프런_20221215(3).png",
        discount : "30% 할인",
        title : "나도코딩의 자바 기본편 - 풀코스 (20시간)",
        name : "나도코딩",
        star :[true,true,true,false,false],
        reviewCnt : false,
        delPrice : "₩71,500",
        price: "₩50,050",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 19,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star :[false,false,false,false,false],
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 20,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/327096/cover/fa1cbc3c-c0e8-4a3d-9f2a-1150b67f6457/327096-eng.png",
        discount : "30% 할인 (D-9)",
        title : "[왕초보편] 앱 8개를 만들면서 배우는 안드로이드 코틀린(Android Kotlin)",
        name : "개복치개발자",
        star :[true,true,true,true,true],
        reviewCnt : "(100)",
        delPrice : "₩24,200",
        price: "₩16,940",
        label : {update:false, new:false, studentCnt :"+1100명", discount:true },
        hashTag : [],
    },
    {  
        index : 21,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/wp-content/uploads/ktw_algorithm3.jpg",
        discount : "30% 할인 (D-9)",
        title : "it 취업을 위한 알고리즘 문제풀이 입문 (with C/C++) : 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(119)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+2900명", discount:true },
        hashTag : [],
    },
    {  
        index : 22,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/326750/cover/d7815167-2a01-42c1-aacf-bbd32a3705bc/326750-eng.png",
        discount : "30% 할인 (D-9)",
        title : "자바(Java) 알고리즘 문제풀이 입문: 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(168)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+4200명", discount:true },
        hashTag : [],
    },
    {  
        index : 23,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/329986/cover/15670e82-a5c6-4fc4-801a-993dfc284627/썸네일_인프런_20221215(3).png",
        discount : "30% 할인",
        title : "나도코딩의 자바 기본편 - 풀코스 (20시간)",
        name : "나도코딩",
        star :[true,true,true,false,false],
        reviewCnt : false,
        delPrice : "₩71,500",
        price: "₩50,050",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 24,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star :[false,false,false,false,false],
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 25,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/327096/cover/fa1cbc3c-c0e8-4a3d-9f2a-1150b67f6457/327096-eng.png",
        discount : "30% 할인 (D-9)",
        title : "[왕초보편] 앱 8개를 만들면서 배우는 안드로이드 코틀린(Android Kotlin)",
        name : "개복치개발자",
        star :[true,true,true,true,true],
        reviewCnt : "(100)",
        delPrice : "₩24,200",
        price: "₩16,940",
        label : {update:false, new:false, studentCnt :"+1100명", discount:true },
        hashTag : [],
    },
    {  
        index : 26,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/wp-content/uploads/ktw_algorithm3.jpg",
        discount : "30% 할인 (D-9)",
        title : "it 취업을 위한 알고리즘 문제풀이 입문 (with C/C++) : 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(119)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+2900명", discount:true },
        hashTag : [],
    },
    {  
        index : 27,
        link : "./",
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/326750/cover/d7815167-2a01-42c1-aacf-bbd32a3705bc/326750-eng.png",
        discount : "30% 할인 (D-9)",
        title : "자바(Java) 알고리즘 문제풀이 입문: 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(168)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+4200명", discount:true },
        hashTag : [],
    },
]
const RoadmapCourseData = [
    {
        index : 1,
        img : "https://cdn.inflearn.com/public/roadmaps/0efaac78-41ca-4145-a99b-ead624f8ec36/roadmap-106.png",
        text : "MMORPG 게임 개발, 켠김에 끝판왕까지! (유니티 + C#)",
    },
    {
        index : 2,
        img : "https://cdn.inflearn.com/public/roadmaps/e43ba925-8d00-41b0-bc92-685418585d1b/roadmap-78.png",
        text : "D 모델링, 입문부터 취업까지! (3dsmax + ZBrush)",
    },
    {
        index : 3,
        img : "https://cdn.inflearn.com/public/roadmaps/c6c69670-7155-423a-bc3a-80d9d3386953/roadmap-93.png",
        text : "자바 개발자 Level Up! - 더 나은 자바 개발자 되기",
    },
    {
        index : 4,
        img : "https://cdn.inflearn.com/public/roadmaps/492/cover/4b96e97c-a26a-4e10-8ca3-cc9f6db936b8/roadmap-164.png",
        text : "꼭 필요하지만 아무도 알려주지 않는 업무 스킬",
    },
    {
        index : 5,
        img : "https://cdn.inflearn.com/public/roadmaps/0efaac78-41ca-4145-a99b-ead624f8ec36/roadmap-106.png",
        text : "MMORPG 게임 개발, 켠김에 끝판왕까지! (유니티 + C#)",
    },
    {
        index : 6,
        img : "https://cdn.inflearn.com/public/roadmaps/e43ba925-8d00-41b0-bc92-685418585d1b/roadmap-78.png",
        text : "D 모델링, 입문부터 취업까지! (3dsmax + ZBrush)",
    },
    {
        index : 7,
        img : "https://cdn.inflearn.com/public/roadmaps/c6c69670-7155-423a-bc3a-80d9d3386953/roadmap-93.png",
        text : "자바 개발자 Level Up! - 더 나은 자바 개발자 되기",
    },
    {
        index : 8,
        img : "https://cdn.inflearn.com/public/roadmaps/492/cover/4b96e97c-a26a-4e10-8ca3-cc9f6db936b8/roadmap-164.png",
        text : "꼭 필요하지만 아무도 알려주지 않는 업무 스킬",
    },
    {
        index : 9,
        img : "https://cdn.inflearn.com/public/roadmaps/0efaac78-41ca-4145-a99b-ead624f8ec36/roadmap-106.png",
        text : "MMORPG 게임 개발, 켠김에 끝판왕까지! (유니티 + C#)",
    },
    {
        index : 10,
        img : "https://cdn.inflearn.com/public/roadmaps/e43ba925-8d00-41b0-bc92-685418585d1b/roadmap-78.png",
        text : "D 모델링, 입문부터 취업까지! (3dsmax + ZBrush)",
    },
    {
        index : 11,
        img : "https://cdn.inflearn.com/public/roadmaps/c6c69670-7155-423a-bc3a-80d9d3386953/roadmap-93.png",
        text : "자바 개발자 Level Up! - 더 나은 자바 개발자 되기",
    },
    {
        index : 12,
        img : "https://cdn.inflearn.com/public/roadmaps/492/cover/4b96e97c-a26a-4e10-8ca3-cc9f6db936b8/roadmap-164.png",
        text : "꼭 필요하지만 아무도 알려주지 않는 업무 스킬",
    },
]
const RecentCourseData = [
    {  
        index : 28,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star : false,
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 29,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329963/cover/d2bd8295-0f8c-4947-9555-68785b871a67/329963-original.png",
        discount : "30% 할인 (D-9)",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 프론트엔드 코스",
        name : "코드캠프",
        star :false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 30,
        link : "./detail",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329497/cover/ad92112c-9329-4ae1-a0f0-3901233b65f1/329497-eng.png",
        discount : "30% 할인",
        title : "Flutter 초입문 왕초보편",
        name : "오준석",
        star : false,
        reviewCnt : false,
        delPrice : "₩99,200",
        price: "₩69,300",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 31,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330030/cover/ac1668df-e6dc-46fb-bab6-fd291aee057d/330030-eng.png",
        discount : "30% 할인",
        title : "웹플로우(Webflow) 시작하기 - 코딩 없이 자유도 높은 프로토타입 만들기",
        name : "일잘러 장피엠",
        star : false,
        reviewCnt : false,
        delPrice : "₩78,100",
        price: "₩54,670",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 32,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329996/cover/58dbc507-8743-4fa6-891f-e61249ad83c1/329996-original.png",
        discount : "30% 할인",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 백엔드 코스",
        name : "코드캠프",
        star : false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 33,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star : false,
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 34,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329963/cover/d2bd8295-0f8c-4947-9555-68785b871a67/329963-original.png",
        discount : "30% 할인 (D-9)",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 프론트엔드 코스",
        name : "코드캠프",
        star :false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 35,
        link : "./detail",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329497/cover/ad92112c-9329-4ae1-a0f0-3901233b65f1/329497-eng.png",
        discount : "30% 할인",
        title : "Flutter 초입문 왕초보편",
        name : "오준석",
        star : false,
        reviewCnt : false,
        delPrice : "₩99,200",
        price: "₩69,300",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 36,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330030/cover/ac1668df-e6dc-46fb-bab6-fd291aee057d/330030-eng.png",
        discount : "30% 할인",
        title : "웹플로우(Webflow) 시작하기 - 코딩 없이 자유도 높은 프로토타입 만들기",
        name : "일잘러 장피엠",
        star : false,
        reviewCnt : false,
        delPrice : "₩78,100",
        price: "₩54,670",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 37,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329996/cover/58dbc507-8743-4fa6-891f-e61249ad83c1/329996-original.png",
        discount : "30% 할인",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 백엔드 코스",
        name : "코드캠프",
        star : false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 38,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star : false,
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 39,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329963/cover/d2bd8295-0f8c-4947-9555-68785b871a67/329963-original.png",
        discount : "30% 할인 (D-9)",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 프론트엔드 코스",
        name : "코드캠프",
        star :false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 40,
        link : "./detail",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329497/cover/ad92112c-9329-4ae1-a0f0-3901233b65f1/329497-eng.png",
        discount : "30% 할인",
        title : "Flutter 초입문 왕초보편",
        name : "오준석",
        star : false,
        reviewCnt : false,
        delPrice : "₩99,200",
        price: "₩69,300",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 41,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330030/cover/ac1668df-e6dc-46fb-bab6-fd291aee057d/330030-eng.png",
        discount : "30% 할인",
        title : "웹플로우(Webflow) 시작하기 - 코딩 없이 자유도 높은 프로토타입 만들기",
        name : "일잘러 장피엠",
        star : false,
        reviewCnt : false,
        delPrice : "₩78,100",
        price: "₩54,670",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
    {  
        index : 42,
        link : "./",
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329996/cover/58dbc507-8743-4fa6-891f-e61249ad83c1/329996-original.png",
        discount : "30% 할인",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 백엔드 코스",
        name : "코드캠프",
        star : false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true },
        hashTag : [],
    },
]
const ReviewContentData = [
    {
        name : "seasquirt 님(수강생)",
        time : "52분 전",
        courseName : "스타트업 A to Z",
        review : "궁금했던 부분을 많이 해결할 수 있는 도움이 되는 강의였습니다. 감사합니다",
    },
    {
        name : "까만돌 님(수강생)",
        time : "1시간 전",
        courseName : "따라하면서 배우는 고박사의 유니티 하이퍼캐주얼게임 시리즈 03",
        review : "소중한 강의 감사합니다.",
    },
    {
        name : "장지예 님(수강생)",
        time : "1시간 전",
        courseName : "모두의 깃 & 깃허브",
        review : "깃을 좀 더 편하게 사용할 수 있게된 것 같아 감사드립니다",
    },
    {
        name : "장지예 님(수강생)",
        time : "52분 전",
        courseName : "스프링 입문 - 코드로 배우는 스프링 부트, 웹 MVC, DB 접근 기술",
        review : "단기간에 자바와 스프링까지 처음 배우기 시작하면서 너무 막막하고 어떻게 공부해야 할지 감도 못 잡고 있었는데 강사님 강의를 듣고 조금씩 이해하고 있습니다. 다른 강의들보다 정말 쌩 입문자들에게 친절한 강의 입니다.",
    },
    {
        name : "신미송 님(수강생)",
        time : "1시간 전",
        courseName : "프론트엔드 날개달기: Vuejs, React 배우기 전에 꼭 알아야하는 지식",
        review : "비전공자 출신으로 애매하던 지식들이 정리돼서 좋아요 처음 개발 시작하시는 분들이나, 느낌으로만 용어를 알고 계신 분들이 보면 좋을것같습니다.",
    },
    {
        name : "picassobaby 님(수강생)",
        time : "1시간 전",
        courseName : "C 프로그래밍 - 입문부터 게임 개발까지",
        review : "굳굳굳굳굳",
    },
    {
        name : "염준호 님(수강생)",
        time : "1시간 전",
        courseName : "제대로 파는 Git & GitHub - by 얄코",
        review : "깃에 대해서 잘 배울 수 있었어요. 모르는 부분에 대한 부분은 강의를 돌려보면서 다시 공부하려 합니다. 좋은 강의 감사합니다.",
    },
    {
        name : "ericserra 님(수강생)",
        time : "1시간 전",
        courseName : "팀 개발을 위한 Git, GitHub 입문",
        review : "좋은 강의 해주셔서 감사합니다. 다음에도 다른 주제로 강의해 주시면 좋을 것 같습니다. 혹시 네이버클라우드 강의 가능하시면 네이버클라우드 강의 부탁 드립니다.",
    },
    {
        name : "임동현 님(수강생)",
        time : "1시간 전",
        courseName : "스프링 입문 - 코드로 배우는 스프링 부트, 웹 MVC, DB 접근 기술",
        review : "스프링을 처음 들어보는데도 잘 따라할 수 있게 만들어주신 강의예요!",
    },
    {
        name : "김강보 님(수강생)",
        time : "1시간 전",
        courseName : "[기초-응용] 다양한 환경을 앤서블(Ansible)로 관리하기 with 베이그런트(Vagrant)",
        review : "내용이 친절합니다.",
    },
    {
        name : "seasquirt 님(수강생)",
        time : "52분 전",
        courseName : "스타트업 A to Z",
        review : "궁금했던 부분을 많이 해결할 수 있는 도움이 되는 강의였습니다. 감사합니다",
    },
    {
        name : "까만돌 님(수강생)",
        time : "1시간 전",
        courseName : "따라하면서 배우는 고박사의 유니티 하이퍼캐주얼게임 시리즈 03",
        review : "소중한 강의 감사합니다.",
    },
    {
        name : "장지예 님(수강생)",
        time : "1시간 전",
        courseName : "모두의 깃 & 깃허브",
        review : "깃을 좀 더 편하게 사용할 수 있게된 것 같아 감사드립니다",
    },
    {
        name : "장지예 님(수강생)",
        time : "52분 전",
        courseName : "스프링 입문 - 코드로 배우는 스프링 부트, 웹 MVC, DB 접근 기술",
        review : "단기간에 자바와 스프링까지 처음 배우기 시작하면서 너무 막막하고 어떻게 공부해야 할지 감도 못 잡고 있었는데 강사님 강의를 듣고 조금씩 이해하고 있습니다. 다른 강의들보다 정말 쌩 입문자들에게 친절한 강의 입니다.",
    },
    {
        name : "신미송 님(수강생)",
        time : "1시간 전",
        courseName : "프론트엔드 날개달기: Vuejs, React 배우기 전에 꼭 알아야하는 지식",
        review : "비전공자 출신으로 애매하던 지식들이 정리돼서 좋아요 처음 개발 시작하시는 분들이나, 느낌으로만 용어를 알고 계신 분들이 보면 좋을것같습니다.",
    },
    {
        name : "picassobaby 님(수강생)",
        time : "1시간 전",
        courseName : "C 프로그래밍 - 입문부터 게임 개발까지",
        review : "굳굳굳굳굳",
    },
    {
        name : "염준호 님(수강생)",
        time : "1시간 전",
        courseName : "제대로 파는 Git & GitHub - by 얄코",
        review : "깃에 대해서 잘 배울 수 있었어요. 모르는 부분에 대한 부분은 강의를 돌려보면서 다시 공부하려 합니다. 좋은 강의 감사합니다.",
    },
    {
        name : "ericserra 님(수강생)",
        time : "1시간 전",
        courseName : "팀 개발을 위한 Git, GitHub 입문",
        review : "좋은 강의 해주셔서 감사합니다. 다음에도 다른 주제로 강의해 주시면 좋을 것 같습니다. 혹시 네이버클라우드 강의 가능하시면 네이버클라우드 강의 부탁 드립니다.",
    },
    {
        name : "임동현 님(수강생)",
        time : "1시간 전",
        courseName : "스프링 입문 - 코드로 배우는 스프링 부트, 웹 MVC, DB 접근 기술",
        review : "스프링을 처음 들어보는데도 잘 따라할 수 있게 만들어주신 강의예요!",
    },
    {
        name : "김강보 님(수강생)",
        time : "1시간 전",
        courseName : "[기초-응용] 다양한 환경을 앤서블(Ansible)로 관리하기 with 베이그런트(Vagrant)",
        review : "내용이 친절합니다.",
    },
    {
        name : "seasquirt 님(수강생)",
        time : "52분 전",
        courseName : "스타트업 A to Z",
        review : "궁금했던 부분을 많이 해결할 수 있는 도움이 되는 강의였습니다. 감사합니다",
    },
    {
        name : "까만돌 님(수강생)",
        time : "1시간 전",
        courseName : "따라하면서 배우는 고박사의 유니티 하이퍼캐주얼게임 시리즈 03",
        review : "소중한 강의 감사합니다.",
    },
    {
        name : "장지예 님(수강생)",
        time : "1시간 전",
        courseName : "모두의 깃 & 깃허브",
        review : "깃을 좀 더 편하게 사용할 수 있게된 것 같아 감사드립니다",
    },
    {
        name : "장지예 님(수강생)",
        time : "52분 전",
        courseName : "스프링 입문 - 코드로 배우는 스프링 부트, 웹 MVC, DB 접근 기술",
        review : "단기간에 자바와 스프링까지 처음 배우기 시작하면서 너무 막막하고 어떻게 공부해야 할지 감도 못 잡고 있었는데 강사님 강의를 듣고 조금씩 이해하고 있습니다. 다른 강의들보다 정말 쌩 입문자들에게 친절한 강의 입니다.",
    },
    {
        name : "신미송 님(수강생)",
        time : "1시간 전",
        courseName : "프론트엔드 날개달기: Vuejs, React 배우기 전에 꼭 알아야하는 지식",
        review : "비전공자 출신으로 애매하던 지식들이 정리돼서 좋아요 처음 개발 시작하시는 분들이나, 느낌으로만 용어를 알고 계신 분들이 보면 좋을것같습니다.",
    },
    {
        name : "picassobaby 님(수강생)",
        time : "1시간 전",
        courseName : "C 프로그래밍 - 입문부터 게임 개발까지",
        review : "굳굳굳굳굳",
    },
    {
        name : "염준호 님(수강생)",
        time : "1시간 전",
        courseName : "제대로 파는 Git & GitHub - by 얄코",
        review : "깃에 대해서 잘 배울 수 있었어요. 모르는 부분에 대한 부분은 강의를 돌려보면서 다시 공부하려 합니다. 좋은 강의 감사합니다.",
    },
    {
        name : "ericserra 님(수강생)",
        time : "1시간 전",
        courseName : "팀 개발을 위한 Git, GitHub 입문",
        review : "좋은 강의 해주셔서 감사합니다. 다음에도 다른 주제로 강의해 주시면 좋을 것 같습니다. 혹시 네이버클라우드 강의 가능하시면 네이버클라우드 강의 부탁 드립니다.",
    },
    {
        name : "임동현 님(수강생)",
        time : "1시간 전",
        courseName : "스프링 입문 - 코드로 배우는 스프링 부트, 웹 MVC, DB 접근 기술",
        review : "스프링을 처음 들어보는데도 잘 따라할 수 있게 만들어주신 강의예요!",
    },
    {
        name : "김강보 님(수강생)",
        time : "1시간 전",
        courseName : "[기초-응용] 다양한 환경을 앤서블(Ansible)로 관리하기 with 베이그런트(Vagrant)",
        review : "내용이 친절합니다.",
    },

]


const CourseData = [
    {  
        index : 1,
        type: "free",
        img : "https://cdn.inflearn.com/public/course-324063-cover/3c856a69-e213-4394-8317-1469386b82cc",
        discount : false,
        title : "웹 게임을 만들며 배우는 React",
        name : "조현영",
        star :[true,true,true,true,true],
        reviewCnt : "(255)",
        delPrice : "무료",
        price: "무료",
        label : {update:true, new:false, studentCnt :"+10000명",discount:false }
    },
    {  
        index : 2,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329667/cover/adc25cdf-e5ed-4724-ad8b-cd56724ba306/인프런_DB_대표이미지.png",
        discount : false,
        title : "시니어 백엔드 개발자가 알려주는 데이터베이스 개론 & SQL",
        name : "쉬운코드",
        star :[true,true,true,true,true],
        reviewCnt : "(9)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+700명",discount:false }
    },
    {  
        index : 3,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329442/cover/9b564bc1-433b-4a85-a2f2-147bf198bdc3/329442-eng.png",
        discount : false,
        title : "시작해보세요! 당신의 첫 지식공유",
        name : "인프런",
        star :[true,true,true,true,true],
        reviewCnt : "(8)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+200명",discount:false }
    },
    {  
        index : 4,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/328001/cover/e11c6e6a-14c6-494a-954d-5fc625eb89a6/328001-eng.png",
        discount : false,
        title : "개발하는 정대리 스위프트 기초 문법",
        name : "개발하는 정대리",
        star :[true,true,true,true,true],
        reviewCnt : "(27)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+2500명",discount:false }
    },
    {  
        index : 5,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/326283/cover/ef7611ae-fcad-4c35-9d2e-fadd0765b28e/graphql-apollo-thumb-2 복사.png",
        discount : false,
        title : "얄팍한 GraphQL과 Apollo",
        name : "얄팍한 코딩사전",
        star :[true,true,true,true,true],
        reviewCnt : "(115)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+2100명",discount:false }
    },
    {  
        index : 6,
        type: "free",
        img : "https://cdn.inflearn.com/public/course-324063-cover/3c856a69-e213-4394-8317-1469386b82cc",
        discount : false,
        title : "웹 게임을 만들며 배우는 React",
        name : "조현영",
        star :[true,true,true,true,true],
        reviewCnt : "(255)",
        delPrice : "무료",
        price: "무료",
        label : {update:true, new:false, studentCnt :"+10000명",discount:false }
    },
    {  
        index : 7,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329667/cover/adc25cdf-e5ed-4724-ad8b-cd56724ba306/인프런_DB_대표이미지.png",
        discount : false,
        title : "시니어 백엔드 개발자가 알려주는 데이터베이스 개론 & SQL",
        name : "쉬운코드",
        star :[true,true,true,true,true],
        reviewCnt : "(9)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+700명",discount:false }
    },
    {  
        index : 8,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329442/cover/9b564bc1-433b-4a85-a2f2-147bf198bdc3/329442-eng.png",
        discount : false,
        title : "시작해보세요! 당신의 첫 지식공유",
        name : "인프런",
        star :[true,true,true,true,true],
        reviewCnt : "(8)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+200명",discount:false }
    },
    {  
        index : 9,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/328001/cover/e11c6e6a-14c6-494a-954d-5fc625eb89a6/328001-eng.png",
        discount : false,
        title : "개발하는 정대리 스위프트 기초 문법",
        name : "개발하는 정대리",
        star :[true,true,true,true,true],
        reviewCnt : "(27)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+2500명",discount:false }
    },
    {  
        index : 10,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/326283/cover/ef7611ae-fcad-4c35-9d2e-fadd0765b28e/graphql-apollo-thumb-2 복사.png",
        discount : false,
        title : "얄팍한 GraphQL과 Apollo",
        name : "얄팍한 코딩사전",
        star :[true,true,true,true,true],
        reviewCnt : "(115)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+2100명",discount:false }
    },
    {  
        index : 11,
        type: "free",
        img : "https://cdn.inflearn.com/public/course-324063-cover/3c856a69-e213-4394-8317-1469386b82cc",
        discount : false,
        title : "웹 게임을 만들며 배우는 React",
        name : "조현영",
        star :[true,true,true,true,true],
        reviewCnt : "(255)",
        delPrice : "무료",
        price: "무료",
        label : {update:true, new:false, studentCnt :"+10000명",discount:false }
    },
    {  
        index : 12,
        type: "free",
        img : "https://cdn.inflearn.com/public/courses/329667/cover/adc25cdf-e5ed-4724-ad8b-cd56724ba306/인프런_DB_대표이미지.png",
        discount : false,
        title : "시니어 백엔드 개발자가 알려주는 데이터베이스 개론 & SQL",
        name : "쉬운코드",
        star :[true,true,true,true,true],
        reviewCnt : "(9)",
        delPrice : "무료",
        price: "무료",
        label : {update:false, new:false, studentCnt :"+700명",discount:false }
    },
    {  
        index : 13,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/329986/cover/15670e82-a5c6-4fc4-801a-993dfc284627/썸네일_인프런_20221215(3).png",
        discount : "30% 할인",
        title : "나도코딩의 자바 기본편 - 풀코스 (20시간)",
        name : "나도코딩",
        star :[true,true,true,false,false],
        reviewCnt : false,
        delPrice : "₩71,500",
        price: "₩50,050",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 14,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star :[false,false,false,false,false],
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 15,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/327096/cover/fa1cbc3c-c0e8-4a3d-9f2a-1150b67f6457/327096-eng.png",
        discount : "30% 할인 (D-9)",
        title : "[왕초보편] 앱 8개를 만들면서 배우는 안드로이드 코틀린(Android Kotlin)",
        name : "개복치개발자",
        star :[true,true,true,true,true],
        reviewCnt : "(100)",
        delPrice : "₩24,200",
        price: "₩16,940",
        label : {update:false, new:false, studentCnt :"+1100명", discount:true }
    },
    {  
        index : 16,
        type: "welcome",
        img : "https://cdn.inflearn.com/wp-content/uploads/ktw_algorithm3.jpg",
        discount : "30% 할인 (D-9)",
        title : "it 취업을 위한 알고리즘 문제풀이 입문 (with C/C++) : 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(119)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+2900명", discount:true }
    },
    {  
        index : 17,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/326750/cover/d7815167-2a01-42c1-aacf-bbd32a3705bc/326750-eng.png",
        discount : "30% 할인 (D-9)",
        title : "자바(Java) 알고리즘 문제풀이 입문: 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(168)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+4200명", discount:true }
    },
    {  
        index : 18,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/329986/cover/15670e82-a5c6-4fc4-801a-993dfc284627/썸네일_인프런_20221215(3).png",
        discount : "30% 할인",
        title : "나도코딩의 자바 기본편 - 풀코스 (20시간)",
        name : "나도코딩",
        star :[true,true,true,false,false],
        reviewCnt : false,
        delPrice : "₩71,500",
        price: "₩50,050",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 19,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star :[false,false,false,false,false],
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 20,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/327096/cover/fa1cbc3c-c0e8-4a3d-9f2a-1150b67f6457/327096-eng.png",
        discount : "30% 할인 (D-9)",
        title : "[왕초보편] 앱 8개를 만들면서 배우는 안드로이드 코틀린(Android Kotlin)",
        name : "개복치개발자",
        star :[true,true,true,true,true],
        reviewCnt : "(100)",
        delPrice : "₩24,200",
        price: "₩16,940",
        label : {update:false, new:false, studentCnt :"+1100명", discount:true }
    },
    {  
        index : 21,
        type: "welcome",
        img : "https://cdn.inflearn.com/wp-content/uploads/ktw_algorithm3.jpg",
        discount : "30% 할인 (D-9)",
        title : "it 취업을 위한 알고리즘 문제풀이 입문 (with C/C++) : 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(119)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+2900명", discount:true }
    },
    {  
        index : 22,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/326750/cover/d7815167-2a01-42c1-aacf-bbd32a3705bc/326750-eng.png",
        discount : "30% 할인 (D-9)",
        title : "자바(Java) 알고리즘 문제풀이 입문: 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(168)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+4200명", discount:true }
    },
    {  
        index : 23,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/329986/cover/15670e82-a5c6-4fc4-801a-993dfc284627/썸네일_인프런_20221215(3).png",
        discount : "30% 할인",
        title : "나도코딩의 자바 기본편 - 풀코스 (20시간)",
        name : "나도코딩",
        star :[true,true,true,false,false],
        reviewCnt : false,
        delPrice : "₩71,500",
        price: "₩50,050",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 24,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star :[false,false,false,false,false],
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 25,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/327096/cover/fa1cbc3c-c0e8-4a3d-9f2a-1150b67f6457/327096-eng.png",
        discount : "30% 할인 (D-9)",
        title : "[왕초보편] 앱 8개를 만들면서 배우는 안드로이드 코틀린(Android Kotlin)",
        name : "개복치개발자",
        star :[true,true,true,true,true],
        reviewCnt : "(100)",
        delPrice : "₩24,200",
        price: "₩16,940",
        label : {update:false, new:false, studentCnt :"+1100명", discount:true }
    },
    {  
        index : 26,
        type: "welcome",
        img : "https://cdn.inflearn.com/wp-content/uploads/ktw_algorithm3.jpg",
        discount : "30% 할인 (D-9)",
        title : "it 취업을 위한 알고리즘 문제풀이 입문 (with C/C++) : 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(119)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+2900명", discount:true }
    },
    {  
        index : 27,
        type: "welcome",
        img : "https://cdn.inflearn.com/public/courses/326750/cover/d7815167-2a01-42c1-aacf-bbd32a3705bc/326750-eng.png",
        discount : "30% 할인 (D-9)",
        title : "자바(Java) 알고리즘 문제풀이 입문: 코딩테스트 대비",
        name : "김태원",
        star :[true,true,true,true,true],
        reviewCnt : "(168)",
        delPrice : "₩77,000",
        price: "₩53,900",
        label : {update:false, new:false, studentCnt :"+4200명", discount:true }
    },

    {  
        index : 28,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star : false,
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 29,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329963/cover/d2bd8295-0f8c-4947-9555-68785b871a67/329963-original.png",
        discount : "30% 할인 (D-9)",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 프론트엔드 코스",
        name : "코드캠프",
        star :false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 30,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329497/cover/ad92112c-9329-4ae1-a0f0-3901233b65f1/329497-eng.png",
        discount : "30% 할인",
        title : "Flutter 초입문 왕초보편",
        name : "오준석",
        star : false,
        reviewCnt : false,
        delPrice : "₩99,200",
        price: "₩69,300",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 31,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330030/cover/ac1668df-e6dc-46fb-bab6-fd291aee057d/330030-eng.png",
        discount : "30% 할인",
        title : "웹플로우(Webflow) 시작하기 - 코딩 없이 자유도 높은 프로토타입 만들기",
        name : "일잘러 장피엠",
        star : false,
        reviewCnt : false,
        delPrice : "₩78,100",
        price: "₩54,670",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 32,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329996/cover/58dbc507-8743-4fa6-891f-e61249ad83c1/329996-original.png",
        discount : "30% 할인",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 백엔드 코스",
        name : "코드캠프",
        star : false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 33,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star : false,
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 34,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329963/cover/d2bd8295-0f8c-4947-9555-68785b871a67/329963-original.png",
        discount : "30% 할인 (D-9)",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 프론트엔드 코스",
        name : "코드캠프",
        star :false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 35,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329497/cover/ad92112c-9329-4ae1-a0f0-3901233b65f1/329497-eng.png",
        discount : "30% 할인",
        title : "Flutter 초입문 왕초보편",
        name : "오준석",
        star : false,
        reviewCnt : false,
        delPrice : "₩99,200",
        price: "₩69,300",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 36,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330030/cover/ac1668df-e6dc-46fb-bab6-fd291aee057d/330030-eng.png",
        discount : "30% 할인",
        title : "웹플로우(Webflow) 시작하기 - 코딩 없이 자유도 높은 프로토타입 만들기",
        name : "일잘러 장피엠",
        star : false,
        reviewCnt : false,
        delPrice : "₩78,100",
        price: "₩54,670",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 37,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329996/cover/58dbc507-8743-4fa6-891f-e61249ad83c1/329996-original.png",
        discount : "30% 할인",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 백엔드 코스",
        name : "코드캠프",
        star : false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 38,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330023/cover/968a4b46-1d64-4b61-a48b-81b741dd7a8e/330023-eng.png",
        discount : "30% 할인",
        title : "수학 없이 시작하는 인공지능 첫걸음: 기초부터 최신 트렌드까지",
        name : "김지훈",
        star : false,
        reviewCnt : false,
        delPrice : "₩88,000",
        price: "₩61,600",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 39,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329963/cover/d2bd8295-0f8c-4947-9555-68785b871a67/329963-original.png",
        discount : "30% 할인 (D-9)",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 프론트엔드 코스",
        name : "코드캠프",
        star :false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 40,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329497/cover/ad92112c-9329-4ae1-a0f0-3901233b65f1/329497-eng.png",
        discount : "30% 할인",
        title : "Flutter 초입문 왕초보편",
        name : "오준석",
        star : false,
        reviewCnt : false,
        delPrice : "₩99,200",
        price: "₩69,300",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 41,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/330030/cover/ac1668df-e6dc-46fb-bab6-fd291aee057d/330030-eng.png",
        discount : "30% 할인",
        title : "웹플로우(Webflow) 시작하기 - 코딩 없이 자유도 높은 프로토타입 만들기",
        name : "일잘러 장피엠",
        star : false,
        reviewCnt : false,
        delPrice : "₩78,100",
        price: "₩54,670",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
    {  
        index : 42,
        type: "recent",
        img : "https://cdn.inflearn.com/public/courses/329996/cover/58dbc507-8743-4fa6-891f-e61249ad83c1/329996-original.png",
        discount : "30% 할인",
        title : "[인프런x코드캠프] 부트캠프에서 만든 고농축 백엔드 코스",
        name : "코드캠프",
        star : false,
        reviewCnt : false,
        delPrice : "₩396,000",
        price: "₩277,200",
        label : {update:false, new:true, studentCnt :false, discount:true }
    },
]

export {FreeCourseData, WelcomeCourseData, RoadmapCourseData, ReviewContentData, RecentCourseData};










