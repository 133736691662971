import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components';

import { useSelector, useDispatch } from "react-redux";
import { MainFooter } from "../Common";
import './CartPage.css'
import { addCart } from "../../reducers/cart";


function CartPage() {

    let cartState = useSelector((state) => { return state.cartReducer })
    let cartLength = cartState.cart.length


    return (
        <>
            <div className="CartMain d-flex">

                <CartMainLeft />
                <CartMainRight />

            </div>

            <MainFooter />

        </>
    )
}

export default CartPage;


const CartMainLeft = () => {
    let cartState = useSelector((state) => { return state.cartReducer })
    let cartLength = cartState.cart.length
    let dispatch = useDispatch();

    return (
        <>
            <div className="CartMainLeft">
                <h1 className="CartMainLeft_h1">수강바구니</h1>
                {
                    cartLength === 0 ?
                        <>
                            <div className="CartMainLeft_empty">
                                <p>담긴 강의가 없습니다.</p>
                                <p>나를 성장 시켜줄 좋은 지식들을 찾아보세요</p>
                                <button className="md-button btmStyle">강의리스트 보기</button>
                            </div>
                        </>
                        :
                        <>
                            <div className="total-select-header">
                                <div className="d-flex">
                                    <div style={{ position: "relative" }}>
                                        <input class="check-input" type="checkbox" data-type="terms" />
                                    </div>
                                    <span className="total-select-title-text">전체선택</span>
                                    <div className="total-select-title-num">
                                        <span>{cartLength}</span>
                                        <span>/</span>
                                        <span>{cartLength}</span>
                                    </div>
                                </div>
                                <button className="md-button btnStyle phone-admit-btn">선택삭제 X</button>
                            </div>
                            {
                                cartState.cart.map((data) => {
                                    return (
                                        <div className="cart-course-card" key={data.index}>
                                            <input class="check-input" type="checkbox" data-type="terms" />
                                            <div className="cart-course-card-img-div">
                                                <Link>
                                                    <img src={data.img} data-src={data.img} className="cart-course-card-img" />
                                                </Link>
                                            </div>
                                            <div className="card-course-card__info">
                                                <div className="card-course-card__info-direct">
                                                    <span>얼리버드 할인&nbsp;|</span>
                                                    <span>&nbsp;&nbsp;23-02-09 까지</span>
                                                </div>
                                                <div className="card-course-card__info-title">
                                                    <Link><span>{data.title}</span></Link>
                                                </div>
                                                <div className="card-course-card__info-name">
                                                    <span>{data.name}</span>
                                                    <span>&nbsp;|&nbsp;&nbsp;무제한 수강</span>
                                                </div>
                                            </div>
                                            <div className="close">
                                                <button class="close-btn" aria-label="장바구니에서 제거" onClick={()=>{
                                                        dispatch( addCart(data.index) )
                                                    }}>
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.807 2.1308C13.0999 1.83791 13.5748 1.83791 13.8676 2.1308C14.1605 2.4237 14.1605 2.89857 13.8676 3.19146L9.05932 7.99979L13.8677 12.8081C14.1605 13.101 14.1605 13.5759 13.8677 13.8688C13.5748 14.1617 13.0999 14.1617 12.807 13.8688L7.99866 9.06045L3.19033 13.8688C2.89744 14.1617 2.42257 14.1617 2.12967 13.8688C1.83678 13.5759 1.83678 13.101 2.12967 12.8081L6.938 7.99979L2.12968 3.19146C1.83679 2.89857 1.83679 2.4237 2.12968 2.1308C2.42257 1.83791 2.89745 1.83791 3.19034 2.1308L7.99866 6.93913L12.807 2.1308Z" fill="#ABB0B5"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div class="cart-course-card__payment">
                                                {
                                                    data.price !=="무료"?
                                                    <>
                                                        <div class="cart-course-card__payment-discount">
                                                            <span class="cart-course-card__payment-discount-percent">{data.discount.substring(0, 2)}%</span>
                                                            <span class="cart-course-card__payment-price-origin">{data.delPrice}</span>
                                                        </div>

                                                        <span class="cart-course-card__price-pay">
                                                            <strong>{data.price} </strong>원
                                                        </span>
                                                    </>
                                                    :
                                                    <span class="cart-course-card__price-pay">
                                                        <strong>무료</strong>
                                                    </span>
                                                }
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </>

                }
            </div>
        </>
    )
}

const CartMainRight = () => {
    return (
        <>
            <div className="CartMainRight">
                <div className="buyer-info-box">
                    <div className="buyer-info-header">
                        <div className="buyer-info-header-title">
                            <span>구매자정보</span>
                            <button>
                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#abb0b5" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"></path></svg>
                            </button>
                        </div>
                        <a>저장</a>
                    </div>
                    <div className="buyer-info-content">
                        <label>
                            이름 <span className="required-sign">*</span>
                        </label>
                        <div class="buyer-info-content-input-box">
                            <input value="" required="" type="text" placeholder="실명 입력" maxlength="100" />
                        </div>
                    </div>
                    <div className="buyer-info-content">
                        <label>
                            이메일 <span className="required-sign">*</span>
                        </label>
                        <div className="buyer-info-content-input-box">
                            <input value="" required="" type="text" placeholder="이메일 입력" maxlength="100" />
                        </div>
                    </div>
                    <div className="buyer-info-content">
                        <label>
                            휴대폰 번호 <span className="required-sign">*</span> (숫자만 입력)
                        </label>
                        <div className="d-flex">
                            <div className="buyer-info-content-input-box phone-number">
                                <select >
                                    <option >+82</option>
                                    <option >+81</option>
                                    <option >+1</option>
                                </select>
                                <input value="" required="" type="text" placeholder="01012341234" maxlength="100" />
                            </div>
                            <button className="md-button btnStyle phone-admit-btn">인증요청</button>
                        </div>
                    </div>
                </div>
                <div className="buyer-info-box">
                    <div className="buyer-info-header">
                        <div className="buyer-info-header-title">
                            <span>쿠폰</span>
                            <button>
                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#abb0b5" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"></path></svg>
                            </button>
                        </div>
                        <a className="coupon-num">사용가능 <span>0</span></a>
                    </div>
                    <div className="buyer-info-content">
                        <div className="d-flex">
                            <div className="buyer-info-content-input-box phone-number">
                                <input className="voucher-input" value="" required="" type="text" placeholder="0" maxlength="100" />
                            </div>
                            <button class="md-button btnStyle phone-admit-btn">쿠폰선택</button>
                        </div>
                    </div>
                    <div className="buyer-info-header" style={{ marginTop: "16px" }}>
                        <div className="buyer-info-header-title">
                            <span>포인트</span>
                            <button>
                                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#abb0b5" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"></path></svg>
                            </button>
                        </div>
                        <a className="coupon-num">보유 <span>0</span></a>
                    </div>
                    <div className="buyer-info-content">
                        <div className="d-flex">
                            <div className="buyer-info-content-input-box phone-number">
                                <input className="voucher-input" value="" required="" type="text" placeholder="1,000잎 이상 사용가능" maxlength="100" />
                            </div>
                            <button class="md-button btnStyle phone-admit-btn">전액사용</button>
                        </div>
                    </div>
                    <div className="price-regular">
                        <span>선택상품 금액</span>

                        <div className="cart-tmpl-number-unit ">
                            <span className="">0</span>
                            <span className="">원</span>
                        </div>
                    </div>
                    <div className="price-discount__summary">
                        <button type="button" className="price-discount-type__btn">
                            <span>할인금액</span>
                            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="#abb0b5" fill-rule="evenodd" clip-rule="evenodd" d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z"></path></svg>
                        </button>

                        <div className="price-discount_price">
                            <span className="">0</span>
                            <span className="">원</span>
                        </div>
                    </div>
                    <div className="price-pay">
                        <span>총 결제금액</span>

                        <div clasName="">
                            <span className="">0</span>
                            <span className="">원</span>
                        </div>

                    </div>

                    <button className="md-button btnStyle pay-btn">결제하기</button>

                    <label className="text-agreement">회원 본인은 주문내용을 확인했으며, <span type="button">구매조건 및 개인정보처리방침</span>과 결제에 동의합니다.</label>
                </div>
                <div className="has-questions">
                    <button type="button">
                        {/* <i className="far fa-comment-alt-dots"></i> */}
                        <FontAwesomeIcon style={{ marginRight: "4px", fontSize: "16px" }} icon={faCommentDots} />
                        <span>문의 바로가기</span>
                    </button>
                </div>
            </div>
        </>
    )
}











