/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronLeft, faPause, faPlay, faChevronRight, faChevronDown, faArrowRight, faFolderPlus, faFolderClosed, faHeart, faCartPlus, faShoppingCart  } from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components';

import {FreeCourseData, WelcomeCourseData, RoadmapCourseData, ReviewContentData, RecentCourseData, CourseData} from './MainPageData.js';
import {MainFooter} from '../Common.js';
import './MainPage.css';
import { addCart } from "../../reducers/cart.js";
import { searchKeyword } from "../../reducers/search";


function MainPage() {
    library.add(farHeart)

    // bottom 캐러샐
    let [BottomCarouselVW, setBottomCarouselVW] = useState(0);
    
    return (
        <>
            {/* main */}
            <div className='main'>

                <TopCarousel/>

                <MainPageSearch/>

                <FreeCourseCarousel />

                <WelcomeCourseCarousel />

                <RoadmapCourseCarousel />

                <div className="curation">
                    <div className="course-container d-flex flex-column">
                        <div className="course-header" style={{ style: "height:36px" }} >
                            <a href="./">
                                <h1 className="">
                                    읽어보기
                                    <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                                </h1>
                            </a>
                        </div>
                        <div className="curation_container">
                            <div className="curation_wrapper">
                                <div className="curation_card">
                                    <a href="">
                                        <div className="course-card d-flex flex-column">
                                            <div className="curation-card-img">
                                                <img src="https://cdn.inflearn.com/public/files/pages/8b854650-7218-4cb8-9eaa-99bdb132dc66/newsletter-word-story.png" />
                                            </div>
                                            <div className="curation-title">
                                                소소한 IT 용어 모음집
                                            </div>
                                            <div className="curation-text">
                                                심심할 때 꺼내먹는인프런 단어짱#소소한 #IT용어 #단어짱

                                                매번 쓰면서도 헷갈리는 용어,명확하게 이해하기 힘든 용어가 있죠.
                                                매주 화요일 발행되는 인프런의 뉴스레터,헬로 인프런에는 다양한 IT 용어의 의미를쉽게 정리한 [인프런 단어짱] 코너가 있는데요.
                                                오늘은 [인프런 단어짱]에 실렸던용어들을 한곳에 모아봤어요!
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="curation_card">
                                    <a href="">
                                        <div className="course-card d-flex flex-column">
                                            <div className="curation-card-img">
                                                <img className="ac-gif__img" src="https://cdn.inflearn.com/public/files/pages/0b700dd1-242e-44de-af28-ba2efc591e90/event-2022award-story-main.jpg" alt="2022 인프런 연말어워드" />
                                            </div>
                                            <div className="curation-title">
                                                2022 인프런 연말어워드
                                            </div>
                                            <div className="curation-text">
                                                인프러너 여러분, 안녕하세요. 벌써 2022년 한 해도 보름밖에 남지 않았네요.
                                                여러분의 2022년은 어떠셨나요? 2022년은 인프런에게도 유달리 각별한 시간이었는데요. 한 해 동안 건강한 지식 생태계를 가꿔나갈 수 있도록 사랑과 관심을 보내주신 모든 분들께 깊이 감사드립니다.
                                                인프런에서는 매 연말마다 인프런과 함께 달리며 배움과 나눔의 의미를 더욱 특별하게 빛내주신 분들께 감사의 마음을 담아 작은 상을 전하고 있어요.
                                                이름하여 2022 인프런 연말어워드! 성장을 향한 여정에 앞장선 올해의 주인공을 소개합니다.
                                                누구나 참여할 수 있는 깜짝 이벤트도 준비되어 있으니 스크롤을 끝까지 내려 확인해주세요 🎁
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="curation_card">
                                    <a href="">
                                        <div className="course-card d-flex flex-column">
                                            <div className="curation-card-img">
                                                <img className="ac-gif__img" src="https://cdn.inflearn.com/public/files/pages/94155132-14cf-49cb-8949-018ac9e4043c/newsletter-infocus-story-05.png" alt="소통왕으로 불리는 어느 플러터 개발자의 이야기" />
                                            </div>
                                            <div className="curation-title">
                                                소통왕으로 불리는 어느 플러터 개발자의 이야기
                                            </div>
                                            <div className="curation-text">
                                                4,500명의 수강생과 함께재미있는 플러터의 세계로!
                                                #개발자 #플러터 #비전공자 #즐거움
                                                매일 힘들어도, 그 속에 즐거운 일은 존재한다고 하죠.맛있는 음식 먹기, 친구와 수다 떨기, 씻고 전기장판 위에 눕기…소소한 행복이 일상에 가득하죠.
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RecentCourseCarousel />

                <ReviewContentDiv />

                <div className="bottom-banner">
                    <div className="bottom-banner__content">
                        <div className="bottom-banner__swiper d-flex" style={{transform:`translateX( ${BottomCarouselVW}vw )`}}>
                            <div className="bottom-banner__swiper-slide1">
                                <div className="container" style={{ position: "relative" }} >
                                    <h1 className="bottom-banner__h1"><span style={{ color: "#404040", fontWeight: "400" }} >지식을 나눠주세요. <br />쉽게 시작하고 합당한 보상을 받을 수 있어요.</span></h1>
                                    <a className="bottom-banner__button" href="./">지식공유 알아보기</a>
                                    <div className="bottom-banner__button-div">
                                        <div className="bottom-banner__before-button btn_not_activated">
                                            <span className="icon"><FontAwesomeIcon icon={faChevronLeft} className="far fa-chevron-left" /></span>
                                        </div>
                                        <div className="bottom-banner__next-button" onClick={()=>{
                                            setBottomCarouselVW(BottomCarouselVW-100)
                                            }}>
                                            <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-banner__swiper-slide2">
                                <div className="container" style={{ position: "relative" }}>
                                    <h1 className="bottom-banner__h1"><span style={{ color: "#404040", fontWeight: "400" }}>모든 팀원이 인프런의 강의들을<br />자유롭게 학습하는 환경을 제공해주세요.</span></h1>
                                    <a className="bottom-banner__button" href="./">비즈니스 알아보기</a>
                                    <div className="bottom-banner__button-div">
                                        <div className="bottom-banner__before-button" onClick={()=>{
                                            setBottomCarouselVW(BottomCarouselVW+100)
                                            }}>
                                            <span className="icon"><FontAwesomeIcon icon={faChevronLeft} className="far fa-chevron-left" /></span>
                                        </div>
                                        <div className="bottom-banner__next-button" onClick={()=>{
                                            setBottomCarouselVW(BottomCarouselVW-100)
                                            }}>
                                            <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-banner__swiper-slide3">
                                <div className="container" style={{ position: "relative" }}>
                                    <h1 className="bottom-banner__h1"><span style={{ color: "#ffffff", fontWeight: "400" }}>당신은 더 좋은 곳에 갈 수 있어요.<br />지금 열려있는 채용공고를 확인해보세요.</span></h1>
                                    <a className="bottom-banner__button" href="./">공고 확인하기</a>
                                    <div className="bottom-banner__button-div">
                                        <div className="bottom-banner__before-button" onClick={()=>{
                                            setBottomCarouselVW(BottomCarouselVW+100)
                                            }}>
                                            <span className="icon"><FontAwesomeIcon icon={faChevronLeft} className="far fa-chevron-left" /></span>
                                        </div>
                                        <div className="bottom-banner__next-button btn_not_activated">
                                            <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="b2b">
                    <section className="b2b-section">
                        <h3 clasName="group-section__title">
                            이미 많은 기업 구성원들이 인프런에서 성장하고 있어요.
                        </h3>
                        <div className="group-section__logo-cover">
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/c085de89-e640-4bc0-afad-efd1e96dbb58/%E1%84%85%E1%85%A1%E1%84%8B%E1%85%B5%E1%86%AB.png" alt="라인" /></div>
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/0add4321-40e2-46db-9aac-3222b25a69c4/%E1%84%8B%E1%85%AE%E1%84%8B%E1%85%A1%E1%84%92%E1%85%A1%E1%86%AB%20%E1%84%92%E1%85%A7%E1%86%BC%E1%84%8C%E1%85%A6%E1%84%83%E1%85%B3%E1%86%AF.png" alt="우아한형제들" /></div>
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/34618dc7-2862-4db0-9bbc-631ca0c8f5b5/sk.png" alt="sk" /></div>
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/1429748f-8c44-4941-8913-752071578eaf/%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%87%E1%85%A5.png" alt="네이버" /></div>
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/e7dd4c2a-8496-4708-b9fd-ae66317e40ad/%E1%84%82%E1%85%A6%E1%86%A8%E1%84%89%E1%85%B3%E1%86%AB.png" alt="넥슨" /></div>
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/10b49bf2-cd9a-4501-ac3c-84059d04f171/%E1%84%89%E1%85%A1%E1%86%B7%E1%84%89%E1%85%A5%E1%86%BC.png" alt="삼성" /></div>
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/a041f2fa-7f40-4517-bdb7-822fc7800d80/%E1%84%8F%E1%85%A1%E1%84%8F%E1%85%A1%E1%84%8B%E1%85%A9.png" alt="카카오" /></div>
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/b47b4ead-5141-46ec-8003-81c6dbc2e5b7/LG.png" alt="LG" /></div>
                            <div className="group-section__logo-el"><img src="https://cdn.inflearn.com/public/group_logo/0/4aa479b9-4e05-4f8e-84b3-1fb7edb60c0c/nc.png" alt="nc" /></div>
                        </div>
                    </section>
                </div>

                <div className="apply">
                    <div className="course-container d-flex flex-column">
                        <div className="course-header">
                            <h1 className="">
                                다양한 서비스를 신청하세요
                            </h1>
                            <p className="">인프런의 지식공유자 ˙ 비즈니스 ˙ 대학생 신청방법에 대해 알아보세요.</p>
                        </div>
                        <div className="apply-wrapper d-flex">
                            <div className="apply-box">
                                <h2>지식공유자 되기</h2>
                                <div>9개월간 온라인 기술 강의로만 1억원!<br />나의 지식을 나눠 사람들에게 배움의 기회를 주고, 의미있는 대가를 가져가세요.</div>
                                <a href="./">
                                    <span>지식공유자 신청하기</span> <span className="icon"><FontAwesomeIcon icon={faArrowRight} className="far fa-arrow-right" /></span>
                                </a>
                            </div>
                            <div className="apply-box">
                                <h2>인프런 비즈니스 신청</h2>
                                <div>모든 팀원의 인프런의 강의들을 자유롭게 학습하는 환경을 제공해주세요.<br />업무 스킬에 집중된 콘텐츠를 통해 최신 트렌드의 업무역량을 습득할 수 있습니다.</div>
                                <a href="./">
                                    <span>비즈니스 신청하기</span> <span className="icon"><FontAwesomeIcon icon={faArrowRight} className="far fa-arrow-right" /></span>
                                </a>
                            </div>
                            <div className="apply-box">
                                <h2>인프런 X 대학생</h2>
                                <div>학교와 인프런이 함께 하여,<br />많은 학생 분들께 정해진 커리큘럼 이외에도 필요한 학습을 보완하고, 더욱 성장할 수 있도록 도와드립니다.</div>
                                <a href="./">
                                    <span>대학생 신청하기</span> <span class="icon"><FontAwesomeIcon icon={faArrowRight} className="far fa-arrow-right" /></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* main end */}
            </div>

            <MainFooter/>

        </>
    )
}



const TopCarousel = () =>{

    // top 캐러샐
    let [topCarouselPage, setTopCarouselPage] = useState(1);
    let [pausePlayBtn, setPausePlayBtn] = useState(faPause);

    let Navbar_text = ["🎉 7th사랑주간","3시간 완강 가능!","왕초보 모여라 😎","로드맵 🌱","Top 50 👑","강의 추천 🧞","신규 강의 🎁",
                        "지식공유신청","인프런 동료찾기","인프런은 🌱" ];
    let [NavbarStyle, setNavbarStyle] = useState(["nav_activated","nav_not_activated","nav_not_activated"
                                                ,"nav_not_activated","nav_not_activated","nav_not_activated","nav_not_activated",
                                                "nav_not_activated","nav_not_activated","nav_not_activated"]);
    let [NavbarPx, setNavbarPx] = useState(0)

    useEffect(()=>{  // page state가 변할때마다 3초 timeout을 만들어서 넘어가도록
        const timeoutId = setTimeout(
            ()=>{
                if(pausePlayBtn===faPause){
                        if(topCarouselPage<10){
                            return setTopCarouselPage(topCarouselPage+1);
                        } else{
                            setTopCarouselPage(1)
                        }
                    } else if(pausePlayBtn===faPlay){
                        clearTimeout(timeoutId)
                    }
                },3000
            );
        return () => clearTimeout(timeoutId);
    },[topCarouselPage, pausePlayBtn]);

    useEffect(()=>{   // page state 변할때 navbar 버튼 스타일 변경

        if(topCarouselPage>=1){
            let tempNavbarStyle = ["nav_not_activated","nav_not_activated","nav_not_activated"
            ,"nav_not_activated","nav_not_activated","nav_not_activated","nav_not_activated",
            "nav_not_activated","nav_not_activated","nav_not_activated"]
            tempNavbarStyle[topCarouselPage-1] = "nav_activated"

            setNavbarStyle(tempNavbarStyle)
        }

        if(topCarouselPage<=5){
            setNavbarPx(0)
        } else if(topCarouselPage===6){
            setNavbarPx(-69)
        } else if(topCarouselPage===7){
            setNavbarPx(-178)
        } else if(topCarouselPage>=8){
            setNavbarPx(-218)
        }

    },[topCarouselPage])



    return (
        <>
                <div className="swiper">
                    <div className="swiper__img">
                        <div className="swiper__img-container d-flex" style={{transform:`translateX( ${ (topCarouselPage-1)*(-100) }vw )`}}>
                            <Link to="./" style={{ backgroundColor: "#46589c" }} >
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#2a376f", backgroundColor: "#b4c3ff" }}  >전체 강의 30% 할인</div>
                                            <div style={{ color: "#2a376f", backgroundColor: "#b4c3ff" }}  ><span>D-10</span></div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }} >인프런 사랑주간 7th</h1>
                                        <p className="" style={{ color: "#ffffff" }} >토스페이 4천원 페이백과 <br />감사 선물도 있어요💙</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/9da11e9c-be0a-49a7-b52e-9c90014507b6/%5Bevent%5D22love_521(46589c).png" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#00a3ff" }} >
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#ffffff", backgroundColor: "#ffe21d" }} >빠르게 경험하는 성장</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }}>바쁘다 바빠 현대사회! <br /> 딱 3시간만 투자하세요 ⏰</h1>
                                        <p className="" style={{ color: "#ffffff" }}>부담은 적게 성장은 빠르게! <br /> 오늘부터 나도 프로 완강러</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/fdfd9381-cce4-42ae-a051-ef86bae2feb3/%5B%E1%84%90%E1%85%A2%E1%84%80%E1%85%B3%E1%84%85%E1%85%A2%E1%86%AB%E1%84%83%E1%85%B5%E1%86%BC%5D3%E1%84%89%E1%85%B5%E1%84%80%E1%85%A1%E1%86%AB_521.webp" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#000000" }} >
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#000000", backgroundColor: "#5fdc28" }} >고민은 이제 그만!</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }}>누구나 쉬운 입문 강의<br />여기 다 모였다! 🐣</h1>
                                        <p className="" style={{ color: "#ffffff" }}>어디서부터 시작해야 할지 모르는<br />당신을 위한 입문 강의</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/2bb11b09-e564-4191-ab4a-c4032d429136/%5B%E1%84%90%E1%85%A2%E1%84%80%E1%85%B3%E1%84%85%E1%85%A2%E1%86%AB%E1%84%83%E1%85%B5%E1%86%BC%5D%E1%84%8B%E1%85%B5%E1%86%B8%E1%84%86%E1%85%AE%E1%86%AB%E1%84%85%E1%85%A6%E1%84%87%E1%85%A6%E1%86%AF_521.webp" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#38e5ff" }}>
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#ffffff", backgroundColor: "#6344ec" }} >추천 학습 로드맵</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#000000" }}>IT 왕초보부터 실무까지<br />인프런 로드맵 📖</h1>
                                        <p className="" style={{ color: "#000000" }}>코딩, 디자인, 게임, 마케팅.. 모든 IT 실무지식!<br />프로로 가는 최고의 길잡이가 되어드릴게요 🔥</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/5207c5ee-e72f-4fc1-aad0-6122d2a55956/%5B%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%92%E1%85%B5%E1%84%8B%E1%85%A5%E1%84%85%E1%85%A9%5D%E1%84%85%E1%85%A9%E1%84%83%E1%85%B3%E1%84%86%E1%85%A2%E1%86%B8%E1%84%8B%E1%85%B5%E1%86%B8%E1%84%86%E1%85%AE%E1%86%AB_main_521.gif" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#000000" }} >
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#000000", backgroundColor: "#ffe18d" }}>매월 업데이트!</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }}>무슨 강의 들을지 고민이라면?<br />현직자 Top 50 강의 보기 👑</h1>
                                        <p className="" style={{ color: "#ffffff" }}>입문부터 실전까지,<br />믿고 보는 실무자 Pick!</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/c7210512-f6e0-4bc8-96fd-aedb81b34a15/%5B%ED%83%9C%EA%B7%B8%EB%9E%9C%EB%94%A9%5DTOP50_521.webp" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#5806f0" }}>
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#000000", backgroundColor: "#ffd012" }}>1분이면 끝!</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }}>강의 고르기 어려울 땐<br />램프 요정아 도와줘! 🌟</h1>
                                        <p className="" style={{ color: "#ffffff" }}>클릭 5번으로 찾아보는 맞춤 입문 강의</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/dd5c29ab-6be1-4bc2-b347-29e0c9388e99/%5B%E1%84%8B%E1%85%B5%E1%84%87%E1%85%A6%E1%86%AB%E1%84%90%E1%85%B3%5D%E1%84%82%E1%85%A1%E1%84%82%E1%85%B3%E1%86%AB%E1%84%8E%E1%85%A9%E1%84%87%E1%85%A9%E1%84%83%E1%85%A12_main_521.png" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#ff7836" }}>
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#ffffff", backgroundColor: "#007bf8" }}>신규 강의</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }}>나만 몰랐었던 이 강의~♬</h1>
                                        <p className="" style={{ color: "#ffffff" }}>매일 업데이트 되는<br />인프런 신규강의를 만나보세요!</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/7dc9730a-1a01-40a7-a509-4e8bceb73570/%5B%EB%A9%94%EC%9D%B8%ED%9E%88%EC%96%B4%EB%A1%9C%5D%EC%8B%A0%EA%B7%9C%EA%B0%95%EC%9D%98_main_521.gif" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#086394" }}>
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#000000", backgroundColor: "#00de84" }}>지식공유신청</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }}>나누는 지식만큼<br />커지는 보람과 보상</h1>
                                        <p className="" style={{ color: "#ffffff" }}>인프런 지식공유자로<br />높은 수익과 가치를 만들어보세요.</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/14394a9e-4b27-4a0f-bc87-c2cae10e2d62/%5B%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%92%E1%85%B5%E1%84%8B%E1%85%A5%E1%84%85%E1%85%A9%5D%E1%84%8C%E1%85%B5%E1%84%89%E1%85%B5%E1%86%A8%E1%84%80%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%B2%E1%84%8C%E1%85%A1_521.gif" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#00c471" }}>
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#ffffff", backgroundColor: "#00dfff" }}>함께해요</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }}>함께 성장할<br />
                                            동료를 찾아요 🌿</h1>
                                        <p className="" style={{ color: "#ffffff" }}>#우리 #함께성장해요<br />
                                            #동료여어디계신가요?</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/68a15e47-7f40-444c-af73-60ab1acb8b3f/%5B%EB%B8%8C%EB%9E%9C%EB%93%9C%5D%EC%B1%84%EC%9A%A9%EC%9D%B4%EB%AF%B8%EC%A7%80%26%EA%B4%91%EA%B3%A0%EB%A6%AC%EB%89%B4%EC%96%BC_main_521.gif" />
                                    </div>
                                </div>
                            </Link>
                            <Link to="./" style={{ backgroundColor: "#59cf92" }}>
                                <div className="swiper__img-content d-flex">
                                    <div className="swiper__img-text">
                                        <div className="swiper__img-tags d-flex">
                                            <div style={{ color: "#59cf92", backgroundColor: "#ffffff" }}>인프런 소개</div>
                                        </div>
                                        <h1 className="bold" style={{ color: "#ffffff" }}>우리는 성장기회의<br />
                                            평등을 추구합니다.</h1>
                                        <p className="" style={{ color: "#ffffff" }}>#누구나 배움의 기회를 누리고 꿈을 이룰 수 있도록!<br />
                                            인프런과 함께 배우고, 나누고, 성장하세요.</p>
                                    </div>
                                    <div className="swiper__img-img ms-auto">
                                        <img src="https://cdn.inflearn.com/public/main_sliders/04081c01-4aee-40ad-a500-5260f1cfd508/main_521_%EC%9D%B8%ED%94%84%EB%9F%B0%EC%86%8C%EA%B0%9C.png" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div class="swiper__navbar">
                        <div class="swiper__navbar-content d-flex">
                            <div class="swiper__pagination item-center">
                                <div class="index-wrapper">
                                    <span class="current-index">{topCarouselPage}</span>/<span class="total-count">10</span>
                                </div>
                                <div class="control-wrapper">
                                    <button type="button" class="control-before-button"onClick={()=>{ 
                                        if(topCarouselPage <= 1){
                                            return setTopCarouselPage(10);
                                        } else {
                                            return setTopCarouselPage(topCarouselPage-1) }
                                        }} >
                                        <FontAwesomeIcon icon={faChevronLeft} className="far fa-chevron-left" />
                                    </button>
                                    <button type="button" class="control-play-pause-button" onClick={()=>{
                                        if (pausePlayBtn==faPause){setPausePlayBtn(faPlay)}
                                        else{setPausePlayBtn(faPause)}                                        
                                    }}>
                                        <FontAwesomeIcon icon={pausePlayBtn}/>
                                        {/* <FontAwesomeIcon icon={faPlay} className="as fa-play" /> */}
                                    </button>
                                    <button type="button" class="control-next-button" onClick={()=>{ 
                                        if(topCarouselPage < 10){
                                            return setTopCarouselPage(topCarouselPage+1);
                                        } else {
                                            return setTopCarouselPage(1) }
                                        }} >
                                        <FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" />
                                    </button>
                                </div>
                            </div>

                            <div class="swiper__divider"></div>

                            <div class="swiper__pagination-wrapper">
                                <div class="swiper__pagination-wrapper-div" style={{transform:`translateX( ${NavbarPx}px )`}}>
                                    {
                                        Navbar_text.map((data, i)=>{
                                            return(
                                                <>
                                                    <span key={i} class={NavbarStyle[i]} onClick={
                                                        ()=>{
                                                            setTopCarouselPage(i+1);
                                                        }
                                                    }>{data}</span>
                                                </>
                                            )
                                        })
                                    }
                                    {/* <span class={NavbarStyle[0]} data-id="1" tabindex="0">🎉7th사랑주간</span>
                                    <span class="" data-id="2" tabindex="0">3시간 완강 가능!</span>
                                    <span class="" data-id="3" tabindex="0">왕초보 모여라 😎</span>
                                    <span class="" data-id="4" tabindex="0">로드맵 🌱</span>
                                    <span class="" data-id="5" tabindex="0">Top 50 👑</span>
                                    <span class="" data-id="6" tabindex="0">강의 추천 🧞</span>
                                    <span class="" data-id="7" tabindex="0">신규 강의 🎁</span>
                                    <span class="" data-id="8" tabindex="0">지식공유신청</span>
                                    <span class="" data-id="9" tabindex="0">인프런 동료찾기</span>
                                    <span class="" data-id="10" tabindex="0">인프런은 🌱</span> */}
                                </div>
                            </div>

                            <button type="button" class="swiper__pagination-btn">
                                <FontAwesomeIcon icon={faChevronDown} className="far fa-chevron-down" />
                            </button>

                        </div>
                    </div>
                </div>
        
        
        </>
    )
}

const MainPageSearch = ()=>{

    const [inputText, setInputText] = useState("");
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const activeButton = ()=>{
        if(inputText!==""){
            dispatch( searchKeyword(inputText) )
            navigate(`./Search/${inputText}`);
        }
    }

    const activeEnter = (e)=>{
        if(e.key==="Enter"){
            activeButton();
        }
    }
    return(
        <>
            <div className="search">
                <div className="search__content">
                    <h1 className="">배우고, 나누고, 성장하세요</h1>
                    <div className="search__wrapper">
                        <input type="text" placeholder="배우고 싶은 지식을 입력해보세요."
                            onChange={(e)=>{setInputText(e.target.value)}} // 입력할때마다 inputText 변화
                            onKeyDown={(e)=>{activeEnter(e)}} // 엔터키 입력시 activeEnter 함수 실행
                        /> 
                        <span>
                            <Link to={ inputText!==""? `./Search/${inputText}` : "./"} onClick={activeButton}>
                                <FontAwesomeIcon icon={faSearch} className="far fa-search" />
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

const FreeCourseCarousel = () => {

    let [FreeDataState, setFreeDataState] = useState(FreeCourseData);
    let [FreeCourseBtn, setFreeCourseBtn] = useState(["btn_not_activated","btn_activated"]);
    let [FreeCoursePx, setFreeCoursePx] = useState(0);

    return (
        <>
            <div className="free-course">
                <div className="course-container d-flex flex-column">
                    <div className="course-header">
                        <a href="./">
                            <h1 className="">
                                무료강의? 오히려 좋아 ✨
                                <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                            </h1>
                        </a>
                        <p className="subtext is-2">무료 강의부터 가볍게 시작해 보세요.</p>
                    </div>
                    <div style={{ position: "relative" }} >
                        <div className={`course-before-btn ${FreeCourseBtn[0]}`} onClick={()=>{ 
                                    if(FreeCoursePx==-1652){
                                    setFreeCoursePx(FreeCoursePx+472)
                                    setFreeCourseBtn(["btn_activated","btn_activated"])
                                    } 
                                    else if(FreeCoursePx==-1180){
                                    setFreeCoursePx(FreeCoursePx+1180)
                                    setFreeCourseBtn(["btn_not_activated","btn_activated"])
                                    } 
                                }}>
                            <FontAwesomeIcon icon={faChevronLeft} className="far fa-chevron-left" />
                        </div>
                        <div className="course-content">
                            <div className="course-content-div free-course-content-div" style={{transform:`translateX( ${FreeCoursePx}px )`}}>
                                <ContentCards DataState={FreeDataState} />
                            </div>
                        </div>
                        <div className={`course-next-btn ${FreeCourseBtn[1]}`} onClick={()=>{ 
                                    if(FreeCoursePx==0){
                                    setFreeCoursePx(FreeCoursePx-1180)
                                    setFreeCourseBtn(["btn_activated","btn_activated"])
                                    } 
                                    else if(FreeCoursePx==-1180){
                                    setFreeCoursePx(FreeCoursePx-472)
                                    setFreeCourseBtn(["btn_activated","btn_not_activated"])
                                    } 
                                }}>
                            <FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const WelcomeCourseCarousel = () => {

    let [WelcomeDataState, setWelcomeDataState] = useState(WelcomeCourseData);
    // card 캐러샐 btn
    let [WelcomeCourseBtn, setWelcomeCourseBtn] = useState(["btn_not_activated","btn_activated"]);
    let [WelcomeCoursePx, setWelcomeCoursePx] = useState(0);

    return(
        <>
            <div className="welcome-course">
                <div className="course-container d-flex flex-column">
                    <div className="course-header">
                        <a href="./">
                            <h1 className="">
                                왕초보도 할 수 있어요 💪
                                <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                            </h1>
                        </a>
                        <p className="subtext is-2">이미 검증된 쉽고 친절한 입문 강의!!</p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <div className={`course-before-btn ${WelcomeCourseBtn[0]}`} onClick={()=>{ 
                                    if(WelcomeCoursePx==-2360){
                                    setWelcomeCoursePx(WelcomeCoursePx+1180)
                                    setWelcomeCourseBtn(["btn_activated","btn_activated"])
                                    } 
                                    else if(WelcomeCoursePx==-1180){
                                    setWelcomeCoursePx(WelcomeCoursePx+1180)
                                    setWelcomeCourseBtn(["btn_not_activated","btn_activated"])
                                    } 
                                }}>
                            <FontAwesomeIcon icon={faChevronLeft} className="far fa-chevron-left" />
                        </div>
                        <div className="course-content">
                            <div className="course-content-div easy-course-content-div" style={{transform:`translateX( ${WelcomeCoursePx}px )`}}>
                                <ContentCards DataState={WelcomeDataState} />
                            </div>
                        </div>
                        <div className={`course-next-btn ${WelcomeCourseBtn[1]}`} onClick={()=>{ 
                                    if(WelcomeCoursePx==0){
                                    setWelcomeCoursePx(WelcomeCoursePx-1180)
                                    setWelcomeCourseBtn(["btn_activated","btn_activated"])
                                    } 
                                    else if(WelcomeCoursePx==-1180){
                                    setWelcomeCoursePx(WelcomeCoursePx-1180)
                                    setWelcomeCourseBtn(["btn_activated","btn_not_activated"])
                                    } 
                                }}>
                            <FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const RoadmapCourseCarousel = () => {
    let [RoadmapDataState, setRoadmapDataState] = useState(RoadmapCourseData);
    // card 캐러샐 btn
    let [RoadmapCourseBtn, setRoadmapCourseBtn] = useState(["btn_not_activated","btn_activated"]);
    let [RoadmapCoursePx, setRoadmapCoursePx] = useState(0);
    return(
        <>
            <div className="roadmap-course">
                <div className="course-container d-flex flex-column">
                    <div className="course-header">
                        <a href="./">
                            <h1 className="">
                                기본부터 실무까지 제시해주는 로드맵 🏃🏻‍♀️ <span className="courses_tag">RoadMap!!</span>
                                <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                            </h1>
                        </a>
                        <p className="subtext is-2">아무것도 몰라도 따라오다 보면 전문가가 될 수 있어요!</p>
                    </div>
                    <div style={{ position: "relative" }}>
                        <div className={`course-before-btn ${RoadmapCourseBtn[0]}`} onClick={()=>{ 
                                    if(RoadmapCoursePx==-2360){
                                    setRoadmapCoursePx(RoadmapCoursePx+1180)
                                    setRoadmapCourseBtn(["btn_activated","btn_activated"])
                                    } 
                                    else if(RoadmapCoursePx==-1180){
                                    setRoadmapCoursePx(RoadmapCoursePx+1180)
                                    setRoadmapCourseBtn(["btn_not_activated","btn_activated"])
                                    } 
                                }}>
                            <FontAwesomeIcon icon={faChevronLeft} className="far fa-chevron-left" />
                        </div>
                        <div className="course-content">
                            <div className="roadmap-course-content-div" style={{transform:`translateX( ${RoadmapCoursePx}px )`}}>
                                {
                                    RoadmapDataState.map(function(data){
                                        return(
                                            <Link to="./" key={data.index} >
                                                <div className="course-card d-flex flex-column">
                                                    <div className="card-img">
                                                        <img src={data.img} alt="로드맵 대표 이미지" />
                                                    </div>
                                                    <div className="roadmap-text" style={{ padding: "1.5rem" }} >
                                                        <h5>{data.text}</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={`course-next-btn ${RoadmapCourseBtn[1]}`} onClick={()=>{ 
                                    if(RoadmapCoursePx==0){
                                    setRoadmapCoursePx(RoadmapCoursePx-1180)
                                    setRoadmapCourseBtn(["btn_activated","btn_activated"])
                                    } 
                                    else if(RoadmapCoursePx==-1180){
                                    setRoadmapCoursePx(RoadmapCoursePx-1180)
                                    setRoadmapCourseBtn(["btn_activated","btn_not_activated"])
                                    } 
                                }}>
                            <FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const RecentCourseCarousel = () => {
    let [RecentDataState, setRecentDataState ] = useState(RecentCourseData);
    let [RecentCourseBtn, setRecentCourseBtn] = useState(["btn_not_activated","btn_activated"]);
    let [RecentCoursePx, setRecentCoursePx] = useState(0);

    return(
        <>
            <div className="recent-course">
                <div className="course-container d-flex flex-column">
                    <div className="course-header">
                        <a href="./">
                            <h1 className="">
                                따끈따끈, 신규 강의를 만나보세요! 🙋🏻‍♀️ <span className="courses_tag">NEW!!</span>
                                <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                            </h1>
                        </a>
                    </div>
                    <div style={{ position: "relative" }}>
                        <div className={`course-before-btn ${RecentCourseBtn[0]}`} onClick={()=>{ 
                                    if(RecentCoursePx==-2360){
                                    setRecentCoursePx(RecentCoursePx+1180)
                                    setRecentCourseBtn(["btn_activated","btn_activated"])
                                    } 
                                    else if(RecentCoursePx==-1180){
                                    setRecentCoursePx(RecentCoursePx+1180)
                                    setRecentCourseBtn(["btn_not_activated","btn_activated"])
                                    } 
                                }}>
                            <FontAwesomeIcon icon={faChevronLeft} className="far fa-chevron-left" />
                        </div>
                        <div className="course-content">
                            <div className="course-content-div recent-course-content-div" style={{transform:`translateX( ${RecentCoursePx}px )`}}>
                                <ContentCards DataState={RecentDataState} />
                            </div>
                        </div>
                        <div className={`course-next-btn ${RecentCourseBtn[1]}`} onClick={()=>{ 
                                    if(RecentCoursePx==0){
                                    setRecentCoursePx(RecentCoursePx-1180)
                                    setRecentCourseBtn(["btn_activated","btn_activated"])
                                    } 
                                    else if(RecentCoursePx==-1180){
                                    setRecentCoursePx(RecentCoursePx-1180)
                                    setRecentCourseBtn(["btn_activated","btn_not_activated"])
                                    } 
                                }}>
                            <FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const ReviewContentDiv = ()=>{
    let [ReviewDataState, setReviewDataState ] = useState(ReviewContentData);
    return(
        <>
            <div className="review">
                <div className="course-container d-flex flex-column">
                    <div className="review-content">
                        <div className="review-content__left">
                            <h1 className="">
                                <span className="" style={{ color: "#47c880" }} >1,005,105</span> 명이 <br />인프런과 함께합니다.
                            </h1>
                            <div className="text">
                                학교에서 배우기 어렵거나 큰 비용을 <br />지불해야만 배울 수 있는 전문적인 지식들을 제공합니다.<br />
                                오픈 플랫폼의 이점을 통해 다양성과 경제성을 모두 높입니다.
                            </div>
                            <Link className="button" to="./">
                                <span>수강평 더보기</span> <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                            </Link>
                            <Link className="button" to="./">
                                <span>기능/강좌 요청하기</span> <span className="icon"><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                            </Link>
                        </div>
                        <div className="review-content__right">
                            <div className="review-content__right-container">
                                <div className="review-content__right-wrapper">
                                    {
                                        ReviewDataState.map(function(data, i){
                                            return(
                                                <div className="review-content__silde-box" key={i} >
                                                    <div className="review-content__detail-box">
                                                        <span >{data.name}</span>
                                                        <span >{data.time}</span>
                                                    </div>
                                                    <div className="box_bottom">
                                                        <Link to="./">
                                                            <span>{data.courseName}</span><span style={{ marginLeft: "3px" }}><FontAwesomeIcon icon={faChevronRight} className="far fa-chevron-right" /></span>
                                                        </Link>
                                                        <div>{data.review}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


// 카드 데이터 반복
const ContentCards = (props) => {
    let dispatch = useDispatch();
    let cartState = useSelector( (state) => state.cartReducer )

    return(
        <>
            {
                props.DataState.map(function(data, i){
                    return(
                        <Link to={data.link} key={data.index}>
                            <div className="course-card d-flex flex-column">
                                <div className="card-img">
                                    <img loading="lazy" src={data.img} data-src={data.img} className="swiper-lazy" alt="image" />
                                    {
                                        data.discount !== false?
                                        <div className="card-ribbon">
                                            <i className="fas fa-badge-percent"></i>
                                            {data.discount}
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="card-text">
                                    <div className="course_title">{data.title}</div>
                                    <div className="instructor">{data.name}</div>
                                    <div className="rating">
                                        {
                                            data.star !== false?
                                            <div className="rating_star">
                                                <div className="star_solid" style={{ whiteSpace: "nowrap", color: "#fdcc11" }}>
                                                    {
                                                        data.star.map(function(starData, index){
                                                            if (starData == true){
                                                                return ( <svg key={index} aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" data-value="1"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg> )
                                                            } else {
                                                                return ( <svg key={index} aria-hidden="true" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16" data-value="5"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg> )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : null
                                        }
                                        {
                                            data.reviewCnt !== false? <span className="review_cnt">{data.reviewCnt}</span> : null
                                        }
                                    </div>
                                        {
                                            data.price === "무료"? <div className="price">무료</div> : <div className="price"><span className="del-text">{data.delPrice}</span> {data.price}</div>
                                        }
                                    
                                    <div className="tags">
                                        {
                                            data.label.studentCnt !== false ? <span className="tag" style={{ backgroundColor: "hsl(321,63%,90%)" }} >{data.label.studentCnt}</span> : null
                                        }
                                        {
                                            data.label.update !== false ?  <span className="tag" style={{ backgroundColor: "hsl(182,75%,94%)" }} >업데이트</span> : null
                                        }
                                        {
                                            data.label.new !== false ? <span className="tag " style={{ backgroundColor: "hsl(182,75%,94%)" }} >새강의</span> : null
                                        }
                                        {
                                            data.label.discount !== false? <span className="tag " style={{ backgroundColor: "hsl(1,100%,89%)" }}>할인중</span> : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="course-card-back">
                                <div className="d-flex flex-column">
                                    <p className="course-card-back-title">{data.title}</p>
                                    <div className="course_level">
                                        <span><svg width="16" aria-hidden="true" data-prefix="fal" data-icon="signal-alt" className="svg-inline--fa fa-signal-alt fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M576 32v448h-32V32h32M416 160v320h-32V160h32M256 288v192h-32V288h32M96 416v64H64v-64h32M576 0h-32c-17.67 0-32 14.33-32 32v448c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32zM416 128h-32c-17.67 0-32 14.33-32 32v320c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V160c0-17.67-14.33-32-32-32zM256 256h-32c-17.67 0-32 14.33-32 32v192c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V288c0-17.67-14.33-32-32-32zM96 384H64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"></path></svg> 초급</span>
                                    </div>
                                    <div className="course_categories">
                                        <span>
                                            <svg width="16" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="folder-tree" className="svg-inline--fa fa-folder-tree fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M288 224h224a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32H400L368 0h-80a32 32 0 0 0-32 32v64H64V8a8 8 0 0 0-8-8H40a8 8 0 0 0-8 8v392a16 16 0 0 0 16 16h208v64a32 32 0 0 0 32 32h224a32 32 0 0 0 32-32V352a32 32 0 0 0-32-32H400l-32-32h-80a32 32 0 0 0-32 32v64H64V128h192v64a32 32 0 0 0 32 32zm0 96h66.74l32 32H512v128H288zm0-288h66.74l32 32H512v128H288z"></path></svg>
                                            백엔드, 웹 개발
                                        </span>
                                    </div>
                                    <div className="course_skills">
                                        <span>
                                            <svg width="16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="cubes" className="svg-inline--fa fa-cubes fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M384 215.1V102.5c0-15-9.3-28.4-23.4-33.7l-92-34.5c-8.1-3.1-17.1-3.1-25.3 0l-92 34.5c-14.1 5.3-23.4 18.7-23.4 33.7v112.6L23.4 254.4C9.3 259.6 0 273.1 0 288.1v106.6c0 13.6 7.7 26.1 19.9 32.2l98.6 49.3c10.1 5.1 22.1 5.1 32.2 0L256 423.6l105.3 52.6c10.1 5.1 22.1 5.1 32.2 0l98.6-49.3c12.2-6.1 19.9-18.6 19.9-32.2V288.1c0-15-9.3-28.4-23.4-33.7L384 215.1zm-116 34.8V152l92-31.7v97.6l-92 32zM152 94.2l104-39 104 39v.2L256 131 152 94.3v-.1zm0 26.1l92 31.7v97.9l-92-32v-97.6zm-30 329.4l-96.8-48.4V308l96.8 39.3v102.4zM25.2 280.8v-.2l109.4-41 108.1 40.5v1.2l-108.1 43.9-109.4-44.4zm122 66.5l95.5-38.8V402l-95.5 47.8V347.3zm217.6 102.4L269.3 402v-93.4l95.5 38.8v102.3zm122-48.4L390 449.7V347.3l96.8-39.3v93.3zm0-120.5l-109.4 44.4-108.1-43.9v-1.2l108.1-40.5 109.4 41v.2z"></path></svg>
                                            Java, Spring, MVC
                                        </span>
                                    </div>
                                    <div className="like_cart_btn">
                                        <Link to='./'><FontAwesomeIcon className={`backCard_icon ${cartState.btnStyle[data.index-1]===true? 'RedCart':''}`} icon={faCartPlus} onClick={()=>{
                                            // dispatch( addCart(data) )
                                            dispatch( addCart( data.index ));
                                        }} /></Link>
                                        {/* <FontAwesomeIcon className="backCard_icon" icon={faShoppingCart} /> */}
                                        <br/>
                                        <Link to='./'><FontAwesomeIcon className="backCard_icon" icon={farHeart} /></Link>
                                        {/* <FontAwesomeIcon className="backCard_icon" icon={faHeart} /> */}
                                        <br/>
                                        <Link to='./'><FontAwesomeIcon className="backCard_icon" icon={faFolderPlus} /></Link>
                                        {/* <FontAwesomeIcon className="backCard_icon" icon={faFolderClosed} /> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                })
            }
        </>
    )
}




export default MainPage;





