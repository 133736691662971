/* eslint-disable */

import { useEffect, useState } from "react";

import { DetailFooter } from "../Common";
import './DetailPage.css'

function DetailPage() {

    // 섹션 펼치기, 접기
    let [SectionBtn, setSectionBtn] = useState(["0px","0px","0px","0px","0px", "0px","0px","0px","0px","0px"]);

    const OpenCloseFunc = (index, px) =>{
        let newSectionBtn = [...SectionBtn]
        if (newSectionBtn[index]=="0px"){
            newSectionBtn[index]= px
            setSectionBtn(newSectionBtn)
        } else{
            newSectionBtn[index]="0px"
            setSectionBtn(newSectionBtn)
        }
    }

    return (
        <>
            <div className="detail-top-description">
                <div className="detail-top-container d-flex">
                    <div className="detail-top-left">
                        <div className="detail-top-thumbnail">
                            <img src="https://cdn.inflearn.com/public/courses/329497/cover/ad92112c-9329-4ae1-a0f0-3901233b65f1/329497-eng.png" alt="Thumbnail" />
                        </div>
                    </div>
                    <div className="detail-top-right">
                        <div className="detail-top-breadcrumb" style={{ alignItems: "center", marginBottom: "6px" }} >
                            <span className="detail-top-breadcrumb-label">NEW</span>

                            <span className="detail-top-breadcrumb-el">개발 · 프로그래밍 {'>'} </span>
                            <span className="detail-top-breadcrumb-el">모바일 앱 개발</span>

                        </div>
                        <div className="detail-top-title" style={{ marginBottom: "40px" }}>
                            <h1 className="cd-header__title">Flutter 초입문 왕초보편</h1>
                        </div>
                        <div className="detail-top-cnt">
                            <span className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="#ffffff" d="M10.333 8c0 1.289-1.044 2.333-2.333 2.333C6.71 10.333 5.667 9.29 5.667 8 5.667 6.711 6.71 5.667 8 5.667c1.289 0 2.333 1.044 2.333 2.333z"></path><path fill="#ffffff" fill-rule="evenodd" d="M8 2.333c-2.288 0-4.083 1.023-5.37 2.16C1.348 5.63.544 6.902.22 7.469.03 7.8.03 8.2.22 8.533c.323.566 1.127 1.838 2.41 2.973 1.287 1.138 3.082 2.16 5.37 2.16 2.288 0 4.083-1.022 5.37-2.16 1.283-1.135 2.087-2.407 2.41-2.973.19-.333.19-.733 0-1.065-.323-.567-1.127-1.839-2.41-2.974-1.287-1.138-3.082-2.16-5.37-2.16zm-6.912 5.63c.295-.516 1.035-1.685 2.205-2.72C4.461 4.21 6.03 3.333 8 3.333c1.97 0 3.54.877 4.707 1.91 1.17 1.035 1.91 2.204 2.205 2.72.008.015.01.028.01.037 0 .01-.002.022-.01.037-.295.516-1.035 1.685-2.205 2.72-1.168 1.033-2.737 1.91-4.707 1.91-1.97 0-3.54-.877-4.707-1.91-1.17-1.035-1.91-2.204-2.205-2.72-.008-.015-.01-.028-.01-.037 0-.01.002-.022.01-.037z" clip-rule="evenodd"></path></svg></span>
                            <span><strong> 21명</strong>이 수강하고 있어요.</span>
                        </div>
                        <div className="detail-top-name" style={{ marginBottom: "8px" }}>
                            <span className=""><svg width="16" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16"><path fill="#ffffff" fill-rule="evenodd" d="M8 1.667c-2.025 0-3.667 1.641-3.667 3.666 0 1.26.636 2.371 1.603 3.031-2.243.822-3.859 2.945-3.933 5.454-.009.277.209.507.485.515.276.008.506-.209.514-.485C3.082 11.158 5.29 9 8 9c2.71 0 4.918 2.157 4.998 4.848.008.276.238.493.514.485.276-.008.493-.239.485-.514-.074-2.51-1.69-4.633-3.933-5.455.967-.66 1.603-1.771 1.603-3.03 0-2.026-1.642-3.667-3.667-3.667zM5.333 5.333c0-1.472 1.194-2.666 2.667-2.666 1.473 0 2.667 1.194 2.667 2.666C10.667 6.806 9.473 8 8 8 6.527 8 5.333 6.806 5.333 5.333z" clip-rule="evenodd"></path></svg></span>
                            <a href="/detail.html" target="_blank" className="">
                                오준석
                                <span className="infd-icon"><svg width="16" xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 16 12"><path fill="#ffffff" d="M13.2 10.5H2.8c-.22 0-.4.169-.4.375v.75c0 .206.18.375.4.375h10.4c.22 0 .4-.169.4-.375v-.75c0-.206-.18-.375-.4-.375zM14.8 3c-.663 0-1.2.504-1.2 1.125 0 .166.04.321.11.464L11.9 5.606c-.385.216-.883.094-1.105-.272L8.758 1.992c.267-.206.442-.515.442-.867C9.2.504 8.663 0 8 0c-.662 0-1.2.504-1.2 1.125 0 .352.175.66.442.867L5.205 5.334c-.223.366-.723.488-1.105.272L2.293 4.59c.067-.14.11-.298.11-.464 0-.621-.538-1.125-1.2-1.125C.54 3 0 3.504 0 4.125S.537 5.25 1.2 5.25c.065 0 .13-.01.193-.019L3.2 9.75h9.6l1.807-4.519c.063.01.128.019.193.019.662 0 1.2-.504 1.2-1.125S15.463 3 14.8 3z"></path></svg></span>
                            </a>
                        </div>
                        <div className="detail-tags d-flex" style={{ alignItems: "center", marginBottom: "8px" }}>
                            <span className="infd-icon"><svg width="16" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16"><path fill="#ffffff" fill-rule="evenodd" d="M5.773 1.455c-.283 0-.518.216-.542.498L5 4.727H2.545c-.3 0-.545.244-.545.546 0 .301.244.545.545.545H4.91l-.364 4.364h-2c-.3 0-.545.244-.545.545 0 .301.244.546.545.546h1.91l-.224 2.684c-.027.317.224.588.542.588.282 0 .518-.216.541-.498l.231-2.774H9.91l-.224 2.684c-.026.317.224.588.542.588.283 0 .518-.216.542-.498L11 11.273h2.454c.302 0 .546-.245.546-.546 0-.301-.244-.545-.546-.545h-2.363l.364-4.364h2c.3 0 .545-.244.545-.545 0-.302-.244-.546-.546-.546h-1.909l.224-2.684c.027-.317-.224-.588-.542-.588-.282 0-.518.216-.541.498l-.232 2.774H6.091l.224-2.684c.026-.317-.224-.588-.542-.588zM6 5.818l-.364 4.364H10l.364-4.364H6z" clip-rule="evenodd"></path></svg></span>

                            <a href="/tag-curation/skill/62" target="_blank" className="cd-header__tag">
                                Flutter
                            </a>
                            <a href="/tag-curation/skill/74" target="_blank" className="cd-header__tag">
                                iOS
                            </a>
                            <a href="/tag-curation/skill/40492" target="_blank" className="cd-header__tag">
                                dart
                            </a>
                            <a href="/tag-curation/skill/75" target="_blank" className="cd-header__tag">
                                Android
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="detail-navbar">
                <span className="cd-menu__el description cd-menu__el--active">
                    <a href="/course/플러터-초입문-왕초보#">강의소개</a></span>
                <span className="cd-menu__el curriculum">
                    <a href="/course/플러터-초입문-왕초보#curriculum">커리큘럼</a></span>
                <span className="cd-menu__el reviews">
                    <a href="/course/플러터-초입문-왕초보#reviews">수강평</a></span>
                <span className="cd-menu__el inquiries">
                    <a href="/course/플러터-초입문-왕초보/inquiries">수강전 문의</a></span>
                <span className="cd-menu__el questions">
                    <a href="/course/플러터-초입문-왕초보/community">커뮤니티</a></span>
                <span className="cd-menu__el news">
                    <a href="/course/플러터-초입문-왕초보/news">새소식</a></span>
            </div>

            <div className="detail-main d-flex">
                <div className="detail-left-panel">
                    <div className="detail-left-panel-wrapper">
                        <div className="cd-body__title">
                            <strong>입문자</strong>를 위해 준비한<br />
                            <strong>[모바일 앱 개발] 강의입니다.</strong>
                        </div>

                        <p className="cd-body__description">이 강의는 플러터(Flutter)를 시작하는 모든 분들이 플러터 기초를 빠르게 학습할 수 있도록 만들었습니다.</p>

                        <section className="cd-fixed-body">
                            <div className="cd-fixed-body__title">
                                ✍️<br />이런 걸<br />배워요!
                            </div>
                            <div className="cd-fixed-body__list-content">
                                <div className="cd-fixed-body__list">
                                    <span className="infd-icon"><svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                        viewBox="0 0 16 16">
                                        <path fill="#00C471" fill-rule="evenodd"
                                            d="M13.78 4.22c.14.14.22.331.22.53s-.08.39-.22.53l-7.25 7.25c-.14.14-.331.22-.53.22s-.39-.08-.53-.22L2.22 9.28c-.133-.142-.205-.33-.201-.525.003-.194.082-.38.22-.517.137-.137.322-.216.516-.22.195-.003.383.07.525.202L6 10.94l6.72-6.72c.14-.14.331-.22.53-.22s.39.08.53.22z"
                                            clip-rule="evenodd"></path>
                                    </svg></span>Flutter로 간단한 앱을 만들 수 있는 최소한의 기본기
                                </div>
                                <div className="cd-fixed-body__list">
                                    <span className="infd-icon"><svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                        viewBox="0 0 16 16">
                                        <path fill="#00C471" fill-rule="evenodd"
                                            d="M13.78 4.22c.14.14.22.331.22.53s-.08.39-.22.53l-7.25 7.25c-.14.14-.331.22-.53.22s-.39-.08-.53-.22L2.22 9.28c-.133-.142-.205-.33-.201-.525.003-.194.082-.38.22-.517.137-.137.322-.216.516-.22.195-.003.383.07.525.202L6 10.94l6.72-6.72c.14-.14.331-.22.53-.22s.39.08.53.22z"
                                            clip-rule="evenodd"></path>
                                    </svg></span>Flutter 개발에 대한 자신감 향상
                                </div>
                                <div className="cd-fixed-body__list">
                                    <span className="infd-icon"><svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                        viewBox="0 0 16 16">
                                        <path fill="#00C471" fill-rule="evenodd"
                                            d="M13.78 4.22c.14.14.22.331.22.53s-.08.39-.22.53l-7.25 7.25c-.14.14-.331.22-.53.22s-.39-.08-.53-.22L2.22 9.28c-.133-.142-.205-.33-.201-.525.003-.194.082-.38.22-.517.137-.137.322-.216.516-.22.195-.003.383.07.525.202L6 10.94l6.72-6.72c.14-.14.331-.22.53-.22s.39.08.53.22z"
                                            clip-rule="evenodd"></path>
                                    </svg></span>Dart 기초 문법
                                </div>
                            </div>
                        </section>

                        <p className="detail-p-example-flutter-des1" style={{}}>
                            입문보다 더 입문, 플러터 초입문 왕초보편!</p>

                        <hr className="detail-hr" />

                        <h3 className="detail-h3-example-flutter">예제로 배우는 Flutter&nbsp;<br /><strong>입문보다 더 입문:
                            초입문 왕초보편</strong>&nbsp;</h3>

                        <p className="detail-p-example-flutter-des2">이 강의에서는 먼저 Flutter의 기초를 익힌 이후 예제를 통해 Flutter의 사용법을 배우게 됩니다. <br />각
                            예제에서 배울 수 있는 내용은 다음과 같습니다.</p>

                        <p className="detail-p-example-flutter-des3"> 6+가지 모바일 앱 예제로 배우는 플러터</p>

                        <div className="card-wrapper card-wrapper-2">
                            <div className="card-el">
                                <div><img className="detail-example-img1"
                                    src="https://cdn.inflearn.com/public/files/courses/327977/33792641-07f5-4b04-926d-8feb19328098/327890-img6.png"
                                    alt="" title="327890-img6.png" height="300" /></div>
                                <sub><strong>비만도 계산기</strong><br />화면 전환
                                    (Navigation)</sub>
                            </div>
                            <div className="card-el">
                                <div><img className="detail-example-img1"
                                    src="https://cdn.inflearn.com/public/files/courses/327890/e5afb9b7-5541-4b2d-a6c4-57c62968feb3/image%208.png"
                                    alt="" height="300" /></div>
                                <sub><strong>스톱워치</strong><br />Timer 활용</sub>
                            </div>
                        </div>

                        <div className="card-wrapper card-wrapper-2">
                            <div className="card-el">
                                <div><img className="detail-example-img1"
                                    src="https://cdn.inflearn.com/public/files/courses/327890/66552618-51a9-4208-a56e-cd22f61f6658/image 9.png"
                                    alt="" title="327890-img6.png" height="300" /></div>
                                <sub><strong>나만의 웹
                                    브라우저</strong><br />WebView</sub>
                            </div>
                            <div className="card-el">
                                <div><img className="detail-example-img1"
                                    src="https://cdn.inflearn.com/public/files/courses/327890/5e2ad584-02b7-4248-a0bd-bdbd1ae89791/image 10.png"
                                    alt="" height="300" /></div>
                                <sub><strong>전자액자</strong><br />ImagePicker, 화면
                                    좌우 스크롤</sub>
                            </div>
                        </div>

                        <div class="card-wrapper card-wrapper-2">
                            <div class="card-el">
                                <div><img className="detail-example-img2"
                                    src="https://cdn.inflearn.com/public/files/courses/327890/9f945e78-ce6d-47e1-94c6-317774ba13ea/image 11.png"
                                    alt="" title="327890-img6.png" height="850"
                                /></div>
                                <sub><b>수평측정기</b><br />가속도계 센서 활용, Stream</sub>
                            </div>
                            <div class="card-el">
                                <div><img className="detail-example-img2"
                                    src="https://cdn.inflearn.com/public/files/courses/327890/a5f20b35-5981-4189-904c-81ea960f2133/image 12.png"
                                    alt="" height="850" /></div>
                                <sub><b>실로폰</b><br />소리 재생</sub>
                            </div>
                        </div>

                        <div class="card-wrapper card-wrapper-2">
                            <div class="card-el">
                                <div><img className="detail-example-img1"
                                    src="https://cdn.inflearn.com/public/files/courses/327890/22d0d9b6-8c6b-4999-8179-60099110e0f5/image 14.png"
                                    alt="" title="327890-img6.png" height="300" /></div>
                                <sub><b>Gps Map</b><br />구글 맵, 위치 정보, 지도 위에 이동경로
                                    그리기</sub>
                            </div>
                            <div class="card-el">
                                <div><img className="detail-example-img1"
                                    src="https://cdn.inflearn.com/public/files/courses/327890/db664808-78f1-4875-8332-505c89f02afa/image 13.png"
                                    alt="" height="300" /></div>
                                <sub><b>TodoList</b><br />Hive</sub>
                            </div>
                        </div>

                        <hr className="detail-hr" />

                        <h3 className="detail-h3-example-flutter2" ><strong>강의 선택 가이드</strong></h3>
                        <p>본인이 공부해야 할 강의가 맞는지 확인해 보세요.</p>

                        <p className="detail-p-example-flutter-des4">
                            <img style={{ width: "100%" }} className="detail-example-img1"
                                src="https://cdn.inflearn.com/public/files/courses/329497/a1110e73-bf89-4bf8-abbd-080ce946ed18/강의 선택 가이드.jpg"
                                alt="" width="752" height="608" />
                        </p>

                        <section className="cd-curriculum" id="curriculum">
                            <div className="cd-curriculum__header">
                                <span className="cd-curriculum__title">커리큘럼</span>
                                <span className="cd-curriculum__sub-title">총 123개 ˙ 7시간 13분의 수업</span>
                            </div>
                            <div className="cd-curriculum__description">
                                <span>이 강의는 영상, 수업 노트가 제공됩니다. 미리보기를 통해 콘텐츠를 확인해보세요.</span>
                                <button className="ac-button is-outlined is-gray e-curri-open-all">모두 펼치기</button>
                            </div>
                            <div className="cd-curriculum__content">
                                <div className="cd-curriculum__accordion cd-accordion">

                                    <div className="cd-accordion__section-cover" onClick={()=>{
                                            OpenCloseFunc(0,"2750px")
                                        }}>
                                        <div className="cd-accordion__section e-curri-open">
                                            {
                                                SectionBtn[0]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }

                                            <span className="cd-accordion__section-title">섹션 0. Hello Flutter</span>
                                            <span className="cd-accordion__section-info">52
                                                강 ∙ 2시간 16분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: SectionBtn[0] }}>
                                            <a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/138060"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">000. 강의 소개</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">04:55</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/126148"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">001. Flutter 란? (1개의 코드로 Android, iOS, Web, Desktop 도
                                                    가능)</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">03:45</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/126149"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">002. Dart 란?</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">02:41</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/126150"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">003. [Windows] Flutter 개발을 위한 설정</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>

                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/126151"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">004. [Mac] Flutter 개발을 위한 설정</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">08:10</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/126152"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">005. Android Studio 에서 새로운 Flutter 프로젝트 만들기</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">03:23</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/126380"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">006. Android 에뮬레이터 작성</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">04:26</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/126718"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">006-1. Android 에뮬레이터를 바깥으로 꺼내기 (디바이스
                                                    프레임 제거)</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">02:39</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/131092"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">007. [Mac] iOS 시뮬레이터 작성</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">02:42</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/131093"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">007-1. [Mac] iOS 실제 기기 연결</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">01:35</span>
                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/132561"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">008. Android 에뮬레이터의 한글 설정</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">01:42</span>
                                                </span></a>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">009. 작성한 프로젝트를 여는 방법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:13</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">010. 프로젝트를 윈도우, Mac 에서 보는 방법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">00:44</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">011. Flutter 프로젝트의 디렉토리 구조 (기본적인
                                                    것만)</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:37</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">012. Widget 이란?</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:25</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">013. StatefulWidget 을 사용하여 화면의 뼈대
                                                    만들기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:11</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">014. Scaffold를 사용하여 머티리얼 디자인의 뼈대와 AppBar
                                                    작성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:53</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">015. Reformat Code 로 코드를 정리하는 법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:49</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">016. flutter_lints 설정 끄기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:11</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">017. FloatingActionButton 사용법, 문장, 세미콜론을 찍는
                                                    이유</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:49</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">018. int 변수, setState, 주석으로 화면 중간의 숫자
                                                    변경하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:55</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">019. Column, Row, mainAxisAlignment 로 중앙
                                                    정렬하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:52</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">020. 여러가지 버튼</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:56</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">021. 파일 나누기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:36</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">022. 파일명, 디렉토리명, 함수명, 변수명 등
                                                    규칙</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:45</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">023. 변수, 함수, 클래스 이름을 한번에 변경하는
                                                    방법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:52</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">024. import 란?</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:09</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">025. 소스 코드에서 값을 검색하는 방법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:28</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">026. Hot Reload 와 Hot Restart</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:12</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">027. 머티리얼 디자인이란?</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:23</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">028. main 함수란?</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:10</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">029. TextField 위젯.mp4</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:02</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">030. Image</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:09</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">031. Container</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:17</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">032. SizedBox</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:56</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">033. 공사중 해결 SingleChildScrollView</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:04</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">034. Flutter Inspector 의 기본적인 사용방법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:22</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">035. Expanded</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:37</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">036. TextField에 입력한 글자를 표시하는 방법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:55</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">037. 버튼을 누르면 글자를 표시하는 방법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:42</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">038. 프로퍼티나 클래스 앞에 _를 붙이는 이유</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:22</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">039. 변수, 인스턴스, 오브젝트, 정수</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:03</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">040. Android Studio 에서 전역 변수와 지역 변수를
                                                    구분하는 방법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:36</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">041. = (대입)</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">00:15</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">042. 세미콜론, 콤마, 콜론</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">00:55</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">043. flutter clean 으로 프로젝트를 깨끗이</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:14</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">044. flutter clean을 수행하면 일어나는 일</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:27</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">045. 작성한 앱을 iOS 시뮬레이터에서 실행해
                                                    보기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">00:31</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">046. 실행과 디버그 차이</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:28</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">047. iOS 스타일의 CupertinoApp</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:43</span>
                                                </span>
                                            </div><a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/135641"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">048. flutter_lints</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>

                                                </span></a><a className="cd-accordion__unit e-course-preview"
                                                    href="/course/플러터-초입문-왕초보/unit/135642"><span className="infd-icon"><svg width="16" height="16"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                        <path fill="#212529"
                                                            d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                        </path>
                                                        <path fill="#212529" fill-rule="evenodd"
                                                            d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                            clip-rule="evenodd"></path>
                                                    </svg></span>
                                                <span className="ac-accordion__unit-title">049. Dart 문법</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>

                                                </span></a>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover" onClick={()=>{
                                            OpenCloseFunc(1,"50px")
                                        }}>
                                        <div className="cd-accordion__section e-curri-open">
                                            {
                                                SectionBtn[1]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 1. 강의 자료</span>
                                            <span className="cd-accordion__section-info">1
                                                강 </span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: SectionBtn[1] }}>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">소스 코드</span>
                                                <span className="ac-accordion__unit-infos">



                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover" onClick={()=>{
                                            OpenCloseFunc(2,"780px")
                                        }}>
                                        <div className="cd-accordion__section e-curri-open">
                                            {
                                                SectionBtn[2]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 2. 비만도 계산기</span>
                                            <span className="cd-accordion__section-info">15
                                                강 ∙ 57분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: SectionBtn[2] }}>
                                            <a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/135643"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">050. 비만도 계산기 해법요약</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">00:59</span>
                                                </span></a>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">051. 비만도 계산기 프로젝트 작성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:50</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">052. Form 과 유효성 검사</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">09:30</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">053. 결과화면 작성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">06:31</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">054. 라이브 템플릿 navpush</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:18</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">055. const 는 어디에 붙여야 하나?</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:53</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">056. 결과 화면에 if문 분기 처리</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">06:12</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">057. 키와 몸무게 String을 double 로 변환</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:03</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">058. SharedPreference 로 간단한 값 저장</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">06:27</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">059. 비동기 처리란? (async - await)</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:12</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">060. Dart 에서 비동기 처리 원칙</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:19</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">061. save, load 기능 구현 (initState)</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:26</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">062. 디버깅, 마지막 값 로드</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:34</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">063. 메서드 오버라이딩</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:14</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">064. 클래스 상속</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:01</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover">
                                        <div className="cd-accordion__section e-curri-open">
                                            {
                                                SectionBtn[3]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 3. 스톱워치</span>
                                            <span className="cd-accordion__section-info">13
                                                강 ∙ 30분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: "0px" }}>
                                            <a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/135660"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">065. 스톱 워치 해법요약</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">00:33</span>
                                                </span></a>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">066. 스톱 워치 프로젝트 생성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:32</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">067. 시작, 일시정지 버튼 UI 작성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:53</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">068. 빈 공간을 차지하는 Spacer</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">00:51</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">069. 타이머 영역 UI 작성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:51</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">070. 랩타임을 표시하는 영역 ListView</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:11</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">071. Timer와 Duration</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:28</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">072. Timer 클래스와 필요한 변수 준비</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:55</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">073. 시작,일시정지 상태 변환 로직</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:39</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">074. 시작,일시정지 동작 로직, 시간표시 UI 수정</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:34</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">075. 초기화</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:37</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">076. 랩타임 기록</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:57</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">077. 랩타임 표시</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:18</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover">
                                        <div className="cd-accordion__section e-curri-open ">
                                            {
                                                SectionBtn[4]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 4. 나만의 웹 브라우저</span>
                                            <span className="cd-accordion__section-info">8
                                                강 ∙ 25분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: "0px" }}>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">078. 프로젝트 작성, WebView 추가</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:06</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">079. 안드로이드 인터넷 권한</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:56</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">080. WebView 기본 설정</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:25</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">081. 옵션 메뉴</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:33</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">082. WebViewController</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:01</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">083. ERR_CLEARTEXT_NOT_PERMITTED 에러 해결법</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:35</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">084. WebView의 javascript 허용</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:43</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">085. WillPopScope로 뒤로가기 제어하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:58</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover">
                                        <div className="cd-accordion__section e-curri-open ">
                                            {
                                                SectionBtn[5]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 5. 전자액자</span>
                                            <span className="cd-accordion__section-info">5
                                                강 ∙ 23분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: "0px" }}>
                                            <a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/135681"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">086. 전자액자 소개</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">00:49</span>
                                                </span></a>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">087. Image Picker 로 사진 선택</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">08:21</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">088. FutureBuilder로 사진 표시하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:46</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">089. PageView로 화면 슬라이드 구현</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:08</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">090. Timer, PageController로 사진 자동 전환하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:13</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover">
                                        <div className="cd-accordion__section e-curri-open ">
                                            {
                                                SectionBtn[6]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 6. 수평 측정기</span>
                                            <span className="cd-accordion__section-info">6
                                                강 ∙ 23분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: "0px" }}>
                                            <a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/137298"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">091. 수평계 앱 소개</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">00:51</span>
                                                </span></a>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">092. Stack, Positioned, MediaQuery</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:43</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">093. BoxDecoration을 사용했을 때 컬러 관련 에러
                                                    처리</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:56</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">094. sensor_plus 로 가속도 센서 값 얻기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">08:28</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">095. 가로모드 고정하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:45</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">096. 센서값을 활용해 수평계 완성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:40</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover">
                                        <div className="cd-accordion__section e-curri-open ">
                                            {
                                                SectionBtn[7]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 7. 실로폰</span>
                                            <span className="cd-accordion__section-info">4
                                                강 ∙ 23분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: "0px" }}>
                                            <a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/137304"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">097. 실로폰 앱 소개</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">00:52</span>
                                                </span></a>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">098. 반복되는 건반 UI 작성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">08:43</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">099. Sound pool 라이브러리 준비</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">09:39</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">100. 건반에 사운드 연결</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:47</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover">
                                        <div className="cd-accordion__section e-curri-open ">
                                            {
                                                SectionBtn[8]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 8. Gps Map</span>
                                            <span className="cd-accordion__section-info">8
                                                강 ∙ 53분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: "0px" }}>
                                            <a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/137809"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">101. 지도 앱 소개</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">00:46</span>
                                                </span></a>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">102. 구글 지도 사용</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">12:27</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">103. 구글 맵 샘플 코드 분석</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">02:59</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">104. Geolocator 패키지로 현재 위치 정보 얻기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">08:51</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">105. Android 에뮬레이터에서 위치 변경하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:44</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">106. 현재 위치로 구글 맵이 표시되도록 하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:29</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">107. 지속적인 위치 정보 얻어서 맵 이동하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">07:03</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">108. 지도위에 이동경로 그리기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">10:43</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cd-accordion__section-cover">
                                        <div className="cd-accordion__section e-curri-open ">
                                            {
                                                SectionBtn[9]==="0px"?
                                                <span className="infd-icon is-close">
                                                    <svg width="16" height="16" viewBox="0 0 16 16"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill="#495057" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12.7803 6.21967C13.0732 6.51256 13.0732 6.98744 12.7803 7.28033L8.53033 11.5303C8.23744 11.8232 7.76256 11.8232 7.46967 11.5303L3.21967 7.28033C2.92678 6.98744 2.92678 6.51256 3.21967 6.21967C3.51256 5.92678 3.98744 5.92678 4.28033 6.21967L8 9.93934L11.7197 6.21967C12.0126 5.92678 12.4874 5.92678 12.7803 6.21967Z">
                                                    </path>
                                                    </svg>
                                                </span>
                                                :
                                                <span className="infd-icon is-open">
                                                    <svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#495057" fill-rule="evenodd"
                                                        d="M3.22 9.78c-.293-.293-.293-.767 0-1.06l4.25-4.25c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06-.293.293-.767.293-1.06 0L8 6.06 4.28 9.78c-.293.293-.767.293-1.06 0z"
                                                        clip-rule="evenodd"></path>
                                                    </svg>
                                                </span>
                                            }
                                            <span className="cd-accordion__section-title">섹션 9. Todo List</span>
                                            <span className="cd-accordion__section-info">11
                                                강 ∙ 1시간 1분</span>
                                        </div>
                                        <div className="cd-accordion__unit-cover" style={{ maxHeight: "0px" }}>
                                            <a className="cd-accordion__unit e-course-preview" href="/course/플러터-초입문-왕초보/unit/138038"><span
                                                className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16">
                                                    <path fill="#212529"
                                                        d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                    </path>
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                        clip-rule="evenodd"></path>
                                                </svg></span>
                                                <span className="ac-accordion__unit-title">109. Todo 리스트 앱 소개</span>
                                                <span className="ac-accordion__unit-infos">

                                                    <span className="ac-accordion__unit-info--preview">미리보기</span>
                                                    <span className="ac-accordion__unit-info--time">00:52</span>
                                                </span></a>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">110. Todo 목록 화면 작성.mp4</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:30</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">111. Todo 추가 화면 작성</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:46</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">112. 모델 클래스 만들고 리스트에 표시하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">07:53</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">113. Hive 사용 준비</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">13:01</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">114. DB의 데이터 표시하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:13</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">115. DB에 Todo 등록</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">05:42</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">116. DB 목록 갱신하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">01:15</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">117. 별도의 컴포넌트 만들고 콜백 사용하기</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">14:34</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">118. DateFormat 활용</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">03:03</span>
                                                </span>
                                            </div>
                                            <div className="cd-accordion__unit"><span className="infd-icon"><svg width="16" height="16"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                                <path fill="#212529"
                                                    d="M6.333 10.39V5.61c0-.263.292-.422.514-.28l3.717 2.39c.204.13.204.43 0 .56l-3.717 2.39c-.222.142-.514-.017-.514-.28z">
                                                </path>
                                                <path fill="#212529" fill-rule="evenodd"
                                                    d="M8 1.667C4.502 1.667 1.667 4.502 1.667 8S4.502 14.333 8 14.333 14.333 11.498 14.333 8 11.498 1.667 8 1.667zM.667 8C.667 3.95 3.95.667 8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8z"
                                                    clip-rule="evenodd"></path>
                                            </svg></span>
                                                <span className="ac-accordion__unit-title">119. 삭제</span>
                                                <span className="ac-accordion__unit-infos">


                                                    <span className="ac-accordion__unit-info--time">04:18</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div className="cd-date__content">
                            <span className="cd-date__published-date">강의 게시일 : 2022년 12월 16일</span>
                            <span className="cd-date__last-updated-at">(마지막 업데이트일 : 2022년 12월 22일)</span>
                        </div>

                    </div>
                </div>

                <div className="detail-right-panel">
                    <div className="detail-right-panel-container">
                        <div className="detail-right-banner">
                            <button className="cd-floating__curation-wrapper">
                                <div className="cd-floating__curation-content">
                                    <img src="https://cdn.inflearn.com/assets/images/corporations/microsoft.png" alt="기업 로고이미지"
                                        className="cd-floating__companyLogo e-curation-logo" />
                                    <div className="cd-floating__messages">
                                        <div className="cd-floating__company-container">
                                            <div className="cd-floating__company e-curation-company">마이크로소프트<div className="cd-floating__highlight"></div>
                                            </div>
                                            <div className="cd-floating__open-modal cd-floating__open-modal--desktop">
                                                <span style={{ fontSize: "12px", textDecoration: "underline",marginRight:"3px" }} >인증</span>
                                                <svg width="6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12">
                                                    <path fill="#212529" fill-rule="evenodd"
                                                        d="M6.834 5.602L1.298.165c-.22-.22-.578-.22-.799 0L.166.498c-.221.22-.221.576 0 .797L4.973 6 .17 10.705c-.22.22-.22.577 0 .797l.334.333c.22.22.578.22.799 0l5.535-5.437c.217-.22.217-.576-.004-.796z">
                                                    </path>
                                                </svg>
                                            </div>
                                        </div>
                                        <p className="cd-floating__description cd-floating__description--pc e-curation-pc">지금 이 회사에
                                            <strong> 관심있는</strong> 사람들도 듣는 중!</p>
                                    </div>
                                </div>
                            </button>
                        </div>

                        <div className="detail-right-option">
                            <div className="cd__discount-banner cd-floating__discount-banner">얼리버드 할인 중</div>

                            <div className="cd-floating__price cd-floating__price--dis">
                                <h4 className="cd-price__discount-rate">30%</h4>
                                <h4>69,300원</h4>
                                <del className="cd-price__reg-price">99,000원</del>
                            </div>

                            <div className="cd-floating__buttons">
                                <button className="e-enrol" data-type="cart">수강신청 하기</button>
                                <button className="e-add-cart" data-type="add-cart">바구니에 담기</button>
                            </div>

                            <div className="cd-floating__sub-buttons">
                                <span className="cd-floating__sub-button e-add-playlist">
                                    <span style={{ marginRight: "8px" }} className="infd-icon"><svg xmlns="http://www.w3.org/2000/svg" width="17"
                                        height="17" viewBox="0 0 16 16">
                                        <path fill="#212529" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M2.5 2.5A.5.5 0 0 0 2 3v10a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5V4.5a.5.5 0 0 0-.5-.5H7.035a1.5 1.5 0 0 1-1.248-.668l-.406-.61a.5.5 0 0 0-.416-.222H2.5zM1 3a1.5 1.5 0 0 1 1.5-1.5h2.465c.501 0 .97.25 1.248.668l.406.61A.5.5 0 0 0 7.035 3H14.5A1.5 1.5 0 0 1 16 4.5V13a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 1 13V3z">
                                        </path>
                                        <path fill="#212529" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M8.5 5.5A.5.5 0 0 1 9 6v5a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5z"></path>
                                        <path fill="#212529" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M11.5 8.5a.5.5 0 0 1-.5.5H6a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5z"></path>
                                    </svg></span>
                                    폴더에 추가
                                </span>
                                <span className="cd-floating__sub-button like-button e-like " data-cnt="7" data-target="PC">
                                    <span style={{ marginRight: "8px" }} className="infd-icon"><svg width="16" height="16" viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#212529" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M4.49095 2.66666C3.10493 2.66666 1.66663 3.92028 1.66663 5.67567C1.66663 7.74725 3.21569 9.64919 4.90742 11.0894C5.73796 11.7965 6.571 12.3653 7.19759 12.7576C7.51037 12.9534 7.7704 13.1045 7.95123 13.2061C7.96818 13.2156 7.98443 13.2247 7.99996 13.2333C8.01549 13.2247 8.03174 13.2156 8.04869 13.2061C8.22952 13.1045 8.48955 12.9534 8.80233 12.7576C9.42892 12.3653 10.262 11.7965 11.0925 11.0894C12.7842 9.64919 14.3333 7.74725 14.3333 5.67567C14.3333 3.92028 12.895 2.66666 11.509 2.66666C10.1054 2.66666 8.9751 3.59266 8.4743 5.09505C8.40624 5.29922 8.21518 5.43693 7.99996 5.43693C7.78474 5.43693 7.59368 5.29922 7.52562 5.09505C7.02482 3.59266 5.89453 2.66666 4.49095 2.66666ZM7.99996 13.8018L8.22836 14.2466C8.08499 14.3202 7.91493 14.3202 7.77156 14.2466L7.99996 13.8018ZM0.666626 5.67567C0.666626 3.368 2.55265 1.66666 4.49095 1.66666C6.01983 1.66666 7.25381 2.48414 7.99996 3.73655C8.74611 2.48414 9.98009 1.66666 11.509 1.66666C13.4473 1.66666 15.3333 3.368 15.3333 5.67567C15.3333 8.22121 13.4657 10.3823 11.7407 11.8509C10.863 12.5982 9.98767 13.1953 9.33301 13.6052C9.00516 13.8104 8.73133 13.9696 8.53847 14.0779C8.44201 14.1321 8.36571 14.1737 8.31292 14.2019C8.28653 14.2161 8.26601 14.2269 8.25177 14.2344L8.2352 14.2431L8.23054 14.2455L8.22914 14.2462C8.22897 14.2463 8.22836 14.2466 7.99996 13.8018C7.77156 14.2466 7.77173 14.2467 7.77156 14.2466L7.76938 14.2455L7.76472 14.2431L7.74815 14.2344C7.73391 14.2269 7.71339 14.2161 7.687 14.2019C7.63421 14.1737 7.55791 14.1321 7.46145 14.0779C7.26858 13.9696 6.99476 13.8104 6.66691 13.6052C6.01225 13.1953 5.13695 12.5982 4.25917 11.8509C2.53423 10.3823 0.666626 8.22121 0.666626 5.67567Z">
                                        </path>
                                    </svg></span>
                                    {/* <span className="infd-icon is-like"><svg width="16" xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16"><path fill="#e5503c" d="M9.333 13.605c-.328.205-.602.365-.795.473-.102.057-.205.113-.308.168h-.002c-.143.074-.313.074-.456 0-.105-.054-.208-.11-.31-.168-.193-.108-.467-.268-.795-.473-.655-.41-1.53-1.007-2.408-1.754C2.534 10.382.667 8.22.667 5.676c0-2.308 1.886-4.01 3.824-4.01 1.529 0 2.763.818 3.509 2.07.746-1.252 1.98-2.07 3.509-2.07 1.938 0 3.824 1.702 3.824 4.01 0 2.545-1.867 4.706-3.592 6.175-.878.747-1.753 1.344-2.408 1.754z"></path></svg></span> */}
                                    <span className="cd-floating__sub-button--cnt">7</span>
                                </span>
                                <span className="cd-floating__sub-button e-share">
                                    <span style={{ marginRight: "8px" }} className="infd-icon"><svg width="16" height="16" viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="#212529" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M13.3334 3.66666C13.3334 4.95533 12.2887 6 11 6C10.3257 6 9.7181 5.7139 9.29211 5.25646L5.88379 7.27067C5.95923 7.50012 6.00004 7.74529 6.00004 8C6.00004 8.25469 5.95923 8.49986 5.8838 8.72932L9.29224 10.7434C9.71822 10.286 10.3257 10 11 10C12.2887 10 13.3334 11.0447 13.3334 12.3333C13.3334 13.622 12.2887 14.6667 11 14.6667C9.71138 14.6667 8.66671 13.622 8.66671 12.3333C8.66671 12.0786 8.70752 11.8335 8.78296 11.604L5.37452 9.58992C4.94854 10.0473 4.34103 10.3333 3.66671 10.3333C2.37804 10.3333 1.33337 9.28866 1.33337 8C1.33337 6.71133 2.37804 5.66666 3.66671 5.66666C4.34102 5.66666 4.94853 5.9527 5.37452 6.41007L8.78295 4.39599L8.78321 4.39678C8.70761 4.1671 8.66671 3.92166 8.66671 3.66666C8.66671 2.378 9.71138 1.33333 11 1.33333C12.2887 1.33333 13.3334 2.378 13.3334 3.66666ZM12.3334 3.66666C12.3334 4.40304 11.7364 4.99999 11 4.99999C10.2637 4.99999 9.66671 4.40304 9.66671 3.66666C9.66671 2.93028 10.2637 2.33333 11 2.33333C11.7364 2.33333 12.3334 2.93028 12.3334 3.66666ZM3.66671 9.33333C4.40309 9.33333 5.00004 8.73637 5.00004 8C5.00004 7.26362 4.40309 6.66666 3.66671 6.66666C2.93033 6.66666 2.33337 7.26362 2.33337 8C2.33337 8.73637 2.93033 9.33333 3.66671 9.33333ZM12.3334 12.3333C12.3334 13.0697 11.7364 13.6667 11 13.6667C10.2637 13.6667 9.66671 13.0697 9.66671 12.3333C9.66671 11.5969 10.2637 11 11 11C11.7364 11 12.3334 11.5969 12.3334 12.3333Z">
                                        </path>
                                    </svg></span>
                                    공유
                                </span>
                            </div>

                            <div className="cd-floating__info bootstrap-components">
                                <div className="cd-floating__info-row">
                                    <span className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16">
                                        <path fill="#212529" d="M8 10c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z"></path>
                                    </svg></span>
                                    지식공유자:&nbsp;<a href="/users/3439">오준석</a>
                                </div>
                                <div className="cd-floating__info-row">
                                    <span className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16">
                                        <path fill="#212529" d="M8 10c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z"></path>
                                    </svg></span>
                                    총 123개 수업 (7시간 13분)
                                </div>
                                <div className="cd-floating__info-row cd-floating__info-row--duration">
                                    <span className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16">
                                        <path fill="#212529" d="M8 10c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z"></path>
                                    </svg></span>
                                    수강기한:&nbsp;<span className="course-duration"><strong>무제한</strong></span>
                                </div>
                                <div className="cd-floating__info-row">
                                    <span className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16">
                                        <path fill="#212529" d="M8 10c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z"></path>
                                    </svg></span>
                                    수료증: 발급
                                </div>
                                <div className="cd-floating__info-row cd-floating__info-row--levels">
                                    <span className="infd-icon"><svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16">
                                        <path fill="#212529" d="M8 10c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2z"></path>
                                    </svg></span>
                                    <div>
                                        난이도:&nbsp;
                                        <span>입문</span>
                                        <span className="infd-icon infd-icon--no-margin">-</span>
                                        <span>초급</span>
                                        <span className="infd-icon infd-icon--no-margin">-</span>
                                        <span>중급이상</span>
                                    </div>
                                </div>

                                <div className="cd-floating__info-row cd-floating__info-row--whetherAnswer__true">
                                    <div>지식공유자 답변이 제공되는 강의입니다</div>
                                </div>
                            </div>

                        </div>

                        <div className="cd-floating__inquiry">
                            <div className="cd-floating__inquiry-header">
                                <span style={{ marginRight: "6px" }}><svg width="16" xmlns="http://www.w3.org/2000/svg" height="16"
                                    viewBox="0 0 16 16">
                                    <path fill="#495057" fill-rule="evenodd"
                                        d="M8 1.5C4.41 1.5 1.5 4.41 1.5 8s2.91 6.5 6.5 6.5 6.5-2.91 6.5-6.5S11.59 1.5 8 1.5zM0 8c0-4.418 3.582-8 8-8s8 3.582 8 8-3.582 8-8 8-8-3.582-8-8zm9 3c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1zM6.92 6.085c.081-.16.19-.299.34-.398.145-.097.371-.187.74-.187.28 0 .553.087.738.225.164.123.262.285.262.525 0 .177-.04.264-.077.318-.046.068-.124.144-.277.245-.076.051-.158.1-.258.161l-.007.004c-.093.056-.204.122-.313.195-.222.148-.487.354-.692.661-.23.345-.137.81.208 1.04.345.23.81.137 1.04-.208.045-.068.124-.143.276-.245.077-.051.159-.1.26-.16l.006-.004c.093-.057.204-.123.313-.195.222-.149.487-.355.692-.662.214-.32.329-.702.329-1.15 0-.76-.36-1.348-.863-1.725C9.155 4.163 8.554 4 8 4c-.631 0-1.155.16-1.572.438-.413.276-.68.638-.849.977-.185.37-.035.82.336 1.006.37.185.82.035 1.006-.336z"
                                        clip-rule="evenodd"></path>
                                </svg></span>수강 전 궁금한 점이 있나요?
                            </div>
                            <a target="_blank" href="/course/플러터-초입문-왕초보/inquiries">
                                문의하기<span className="infd-icon"><svg width="16" height="16" viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#24292e" clip-rule="evenodd"
                                        d="m6.22 3.22c.293-.293.767-.293 1.06 0l4.25 4.25c.293.293.293.767 0 1.06l-4.25 4.25c-.293.293-.767.293-1.06 0s-.293-.767 0-1.06l3.72-3.72-3.72-3.72c-.293-.293-.293-.767 0-1.06z"
                                        fill-rule="evenodd"></path>
                                </svg></span></a>
                        </div>

                    </div>
                </div>
            </div>

            <div className="detail-sub-course">
                <div className="detail-sub-course-container">

                    <div className="sub-courses__header">
                        <a href="./detail.html" className="sub-courses__title">
                            오준석님의 다른 강의 <span className="infd-icon"><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path fill="#212529"
                                    d="M18.258 1.25l-5.578.006c-.13 0-.256.052-.348.144-.093.092-.145.217-.145.348v1.139c0 .065.014.13.039.19s.062.115.11.161c.046.046.101.082.162.106.061.024.126.036.192.035l2.516-.093.07.07-9.53 9.532c-.039.038-.07.083-.09.133-.02.05-.031.103-.031.157 0 .054.01.108.031.158.02.05.051.095.09.133l.785.786c.039.038.084.069.134.09.05.02.103.03.157.03.054 0 .107-.01.157-.03.05-.021.095-.052.133-.09l9.532-9.532.07.07-.093 2.517c-.001.066.01.13.035.192.024.06.06.116.106.163s.1.084.16.11c.061.024.126.038.191.037h1.139c.13 0 .256-.051.348-.144.092-.092.144-.217.144-.348l.006-5.578c0-.13-.052-.256-.144-.348-.093-.092-.218-.144-.348-.144zm-2.242 9.844h-.547c-.145 0-.284.057-.387.16-.103.103-.16.242-.16.387v5.263c0 .055-.022.107-.06.145-.039.039-.09.06-.145.06H3.096c-.055 0-.107-.021-.145-.06-.039-.038-.06-.09-.06-.145V5.284c0-.055.021-.107.06-.146.038-.038.09-.06.145-.06h5.263c.145 0 .285-.057.387-.16.103-.103.16-.242.16-.387v-.547c0-.145-.057-.284-.16-.386-.102-.103-.242-.16-.387-.16H2.891c-.436 0-.853.172-1.16.48-.308.308-.481.725-.481 1.16V17.11c0 .435.173.853.48 1.16.308.308.725.481 1.16.481h12.032c.435 0 .852-.173 1.16-.48.308-.308.48-.726.48-1.16v-5.47c0-.144-.057-.284-.16-.386-.102-.103-.241-.16-.386-.16z">
                                </path>
                            </svg></span>
                        </a>
                        <div className="sub-courses__sub-title">지식공유자님의 다른 강의를 만나보세요!</div>
                    </div>

                    <div className="sub-courses__courses-list">

                        <div className="d-flex" >
                            <div className="course-card-item">
                                <a href="./detail.html" className="media-left">
                                    <figure className="">
                                        <img loading="lazy"
                                            src="https://cdn.inflearn.com/public/courses/328068/cover/4229312e-1e80-4502-9a44-2228f06805a3/328068-eng.jpg"
                                            data-src="https://cdn.inflearn.com/public/courses/328068/cover/4229312e-1e80-4502-9a44-2228f06805a3/328068-eng.jpg"
                                            className="swiper-lazy" alt="328068-eng.jpg" />
                                    </figure>
                                </a>

                                <div className="content_container d-flex">
                                    <a href="/course/플러터-중급" className="course_info">
                                        <h2 href="/course/플러터-중급" className="course_title">Flutter 중급 - 클린 아키텍처</h2>
                                        <div className="tags detail-sub-course-tags">
                                            <span className="sub-course-tag is-rounded">Flutter</span>
                                            <span className="sub-course-tag is-rounded">iOS</span>
                                            <span className="sub-course-tag is-rounded">Android</span>
                                        </div>
                                        <div className="rating">
                                            <div className="rating_star">
                                                <div className="star_solid detail-star_solid">
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="1">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="2">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="3">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="4">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="5">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <span className="review_cnt">(46)</span>
                                        </div>
                                    </a>

                                    <div className="course_price">

                                        <div className="product_amount">
                                            <span>
                                                <del>₩99,000</del>
                                                <span className="pay_price hidden_to_group">₩69,300</span>
                                            </span>
                                        </div>


                                    </div>

                                </div>


                            </div>


                        </div>

                        <div className="d-flex" >
                            <div className="course-card-item">
                                <a href="./detail.html" className="media-left">
                                    <figure className="">
                                        <img loading="lazy"
                                            src="https://cdn.inflearn.com/public/courses/327890/cover/767897fa-36b3-4b8a-a4aa-2355ec4b5602/327890-eng.png"
                                            className="swiper-lazy" alt="328068-eng.jpg" />
                                    </figure>
                                </a>

                                <div className="content_container d-flex">
                                    <a href="/course/플러터-중급" className="course_info">
                                        <h2 href="/course/플러터-중급" className="course_title">모던 안드로이드 - Jetpack Compose 입문</h2>
                                        <div className="tags .detail-sub-course-tags">
                                            <span className="sub-course-tag is-rounded">Jetpack</span>
                                            <span className="sub-course-tag is-rounded">Kotlin</span>
                                            <span className="sub-course-tag is-rounded">Android</span>
                                        </div>
                                        <div className="rating">
                                            <div className="rating_star">
                                                <div className="star_solid detail-star_solid">
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="1">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="2">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="3">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="4">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="5">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <span className="review_cnt">(8)</span>
                                        </div>
                                    </a>

                                    <div className="course_price">

                                        <div className="product_amount">
                                            <span>
                                                <del>₩77,000</del>
                                                <span className="pay_price hidden_to_group">₩53,900</span>
                                            </span>
                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>

                        <div className="d-flex" >
                            <div className="course-card-item">
                                <a href="./detail.html" className="media-left">
                                    <figure className="">
                                        <img loading="lazy"
                                            src="https://cdn.inflearn.com/public/courses/329235/cover/02b457b5-56de-4797-9a3e-17ba2470ae85/329235-eng.png"
                                            data-src="https://cdn.inflearn.com/public/courses/328068/cover/4229312e-1e80-4502-9a44-2228f06805a3/328068-eng.jpg"
                                            className="swiper-lazy" alt="328068-eng.jpg" />
                                    </figure>
                                </a>

                                <div className="content_container d-flex">
                                    <a href="/course/플러터-중급" className="course_info">
                                        <h2 href="/course/플러터-중급" className="course_title">Flutter 실전 앱 개발 - 미국 주식 앱 (with 클린 아키텍쳐) </h2>
                                        <div className="tags .detail-sub-course-tags" >
                                            <span className="sub-course-tag is-rounded">Flutter</span>
                                            <span className="sub-course-tag is-rounded">iOS</span>
                                            <span className="sub-course-tag is-rounded">Android</span>
                                        </div>
                                        <div className="rating">
                                            <div className="rating_star">
                                                <div className="star_solid detail-star_solid">
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="1">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="2">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="3">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="4">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="5">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <span className="review_cnt">(10)</span>
                                        </div>
                                    </a>

                                    <div className="course_price">

                                        <div className="product_amount">
                                            <span>
                                                <del>₩55,000</del>
                                                <span className="pay_price hidden_to_group">₩38,500</span>
                                            </span>
                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>

                        <div className="d-flex" >
                            <div className="course-card-item">
                                <a href="./detail.html" className="media-left">
                                    <figure className="">
                                        <img loading="lazy"
                                            src="https://cdn.inflearn.com/public/courses/287760/cover/442e883c-66ce-4b2f-9dd2-03c954eef60e/287760-eng.png"
                                            data-src="https://cdn.inflearn.com/public/courses/328068/cover/4229312e-1e80-4502-9a44-2228f06805a3/328068-eng.jpg"
                                            className="swiper-lazy" alt="328068-eng.jpg" />
                                    </figure>
                                </a>

                                <div className="content_container d-flex">
                                    <a href="/course/플러터-중급" className="course_info">
                                        <h2 href="/course/플러터-중급" className="course_title">Flutter 입문 - 안드로이드, IOS 개발을 한 번에</h2>
                                        <div className="tags .detail-sub-course-tags" >
                                            <span className="sub-course-tag is-rounded">Flutter</span>
                                            <span className="sub-course-tag is-rounded">iOS</span>
                                            <span className="sub-course-tag is-rounded">Android</span>
                                        </div>
                                        <div className="rating">
                                            <div className="rating_star">
                                                <div className="star_solid detail-star_solid">
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="1">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="2">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="3">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="4">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                    <svg aria-hidden="true" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-18"
                                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="16" height="16"
                                                        data-value="5">
                                                        <path fill="currentColor"
                                                            d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z">
                                                        </path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <span className="review_cnt">(168)</span>
                                        </div>
                                    </a>

                                    <div className="course_price">

                                        <div className="product_amount">
                                            <span>
                                                <del>₩66,000</del>
                                                <span className="pay_price hidden_to_group">₩46,200</span>
                                            </span>
                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>

                    </div>

                </div>
            </div>

            <div class="detail-apply">
                <div class="detail-apply-container">

                    <div class="sub-courses__header">
                        <a target="_blank" href="https://www.rallit.com/positions?jobSkillKeywords=Flutter%2CiOS%2Cdart%2CAndroid"
                            class="sub-courses__title">
                            관련 채용 공고 <span class="infd-icon"><svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path fill="#212529"
                                    d="M18.258 1.25l-5.578.006c-.13 0-.256.052-.348.144-.093.092-.145.217-.145.348v1.139c0 .065.014.13.039.19s.062.115.11.161c.046.046.101.082.162.106.061.024.126.036.192.035l2.516-.093.07.07-9.53 9.532c-.039.038-.07.083-.09.133-.02.05-.031.103-.031.157 0 .054.01.108.031.158.02.05.051.095.09.133l.785.786c.039.038.084.069.134.09.05.02.103.03.157.03.054 0 .107-.01.157-.03.05-.021.095-.052.133-.09l9.532-9.532.07.07-.093 2.517c-.001.066.01.13.035.192.024.06.06.116.106.163s.1.084.16.11c.061.024.126.038.191.037h1.139c.13 0 .256-.051.348-.144.092-.092.144-.217.144-.348l.006-5.578c0-.13-.052-.256-.144-.348-.093-.092-.218-.144-.348-.144zm-2.242 9.844h-.547c-.145 0-.284.057-.387.16-.103.103-.16.242-.16.387v5.263c0 .055-.022.107-.06.145-.039.039-.09.06-.145.06H3.096c-.055 0-.107-.021-.145-.06-.039-.038-.06-.09-.06-.145V5.284c0-.055.021-.107.06-.146.038-.038.09-.06.145-.06h5.263c.145 0 .285-.057.387-.16.103-.103.16-.242.16-.387v-.547c0-.145-.057-.284-.16-.386-.102-.103-.242-.16-.387-.16H2.891c-.436 0-.853.172-1.16.48-.308.308-.481.725-.481 1.16V17.11c0 .435.173.853.48 1.16.308.308.725.481 1.16.481h12.032c.435 0 .852-.173 1.16-.48.308-.308.48-.726.48-1.16v-5.47c0-.144-.057-.284-.16-.386-.102-.103-.241-.16-.386-.16z">
                                </path>
                            </svg></span>
                        </a>
                        <div class="sub-courses__sub-title">배운 지식을 펼쳐보세요!</div>
                    </div>

                    <div class="sub-courses__content">
                        <div class="position-card" data-id="916" data-hasreward="false">
                            <a href="https://www.rallit.com/positions/916?from=inflearn" rel="noreferrer noopener" target="_blank">
                                <figure className="sub-course__content-figure">
                                    <img src="https://cdn.rallit.com/image/2022-11-02/WwPetAURjlakbCwJxm7HW.jpg" alt="프론트엔드" 개발자="" 모집=""
                                        loading="lazy" />
                                </figure>
                                <div class="position-card__content">
                                    <div class="position-card__content-title">
                                        (주)쓰리뷰
                                    </div>
                                    <div class="position-card__content-position">
                                        프론트엔드 개발자 모집
                                    </div>
                                    <div class="position-card__content-detail">
                                        <span class="position-card__career">
                                            미들 (4~8년)
                                        </span>
                                        <i class="position-card__divider"> </i>
                                        <address class="position-card__location">
                                            경기
                                        </address>
                                    </div>
                                    <div class="position-card__content-duration">
                                        채용 시 마감
                                    </div>

                                </div>
                            </a>
                        </div>
                        <div class="position-card" data-id="916" data-hasreward="false">
                            <a href="https://www.rallit.com/positions/916?from=inflearn" rel="noreferrer noopener" target="_blank">
                                <figure className="sub-course__content-figure">
                                    <img src="https://cdn.rallit.com/image/2022-02-07/Qfbo2erf213UXDZe39EbT.png" alt="프론트엔드" 개발자="" 모집=""
                                        loading="lazy" />
                                </figure>
                                <div class="position-card__content">
                                    <div class="position-card__content-title">
                                        슬릭코퍼레이션
                                    </div>
                                    <div class="position-card__content-position">
                                        Front-ent Engineer
                                    </div>
                                    <div class="position-card__content-detail">
                                        <span class="position-card__career">
                                            미들 (4~8년)
                                        </span>
                                        <i class="position-card__divider"> </i>
                                        <address class="position-card__location">
                                            서울
                                        </address>
                                    </div>
                                    <div class="position-card__content-duration">
                                        채용 시 마감
                                    </div>

                                </div>
                            </a>
                        </div>
                        <div class="position-card" data-id="916" data-hasreward="false">
                            <a href="https://www.rallit.com/positions/916?from=inflearn" rel="noreferrer noopener" target="_blank">
                                <figure className="sub-course__content-figure">
                                    <img src="https://cdn.rallit.com/image/2022-02-15/d6uPLKCDpmVzu7Tzqx6pC.png" alt="프론트엔드" 개발자="" 모집=""
                                        loading="lazy" />
                                </figure>
                                <div class="position-card__content">
                                    <div class="position-card__content-title">
                                        문토
                                    </div>
                                    <div class="position-card__content-position">
                                        플러터 개발자
                                    </div>
                                    <div class="position-card__content-detail">
                                        <span class="position-card__career">
                                            경력 무관
                                        </span>
                                        <i class="position-card__divider"> </i>
                                        <address class="position-card__location">
                                            서울
                                        </address>
                                    </div>
                                    <div class="position-card__content-duration">
                                        채용 시 마감
                                    </div>

                                </div>
                            </a>
                        </div>
                        <div class="position-card" data-id="916" data-hasreward="false" style={{ marginRight: "0px" }}>
                            <a href="https://www.rallit.com/positions/916?from=inflearn" rel="noreferrer noopener" target="_blank">
                                <figure className="sub-course__content-figure">
                                    <img src="https://cdn.rallit.com/image/2022-03-03/8nmWsHsI_xeU8_ZU5ydLU.jpg" alt="프론트엔드" 개발자="" 모집=""
                                        loading="lazy" />
                                </figure>
                                <div class="position-card__content">
                                    <div class="position-card__content-title">
                                        (주)데이터뱅크
                                    </div>
                                    <div class="position-card__content-position">
                                        안드로이드 개발자
                                    </div>
                                    <div class="position-card__content-detail">
                                        <span class="position-card__career">
                                            경력무관
                                        </span>
                                        <i class="position-card__divider"> </i>
                                        <address class="position-card__location">
                                            서울
                                        </address>
                                    </div>
                                    <div class="position-card__content-duration">
                                        채용 시 마감
                                    </div>

                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>

            <DetailFooter/>

        </>
    )
}

export default DetailPage;


