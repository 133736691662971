const initialState = ''

const loginReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'LOGIN': {
            if( state=='' ){
              state = 'show-modal'
            } else{
              state = ''
            }
            return state
        }
        default: {
            return state
        }
    }
}


export default loginReducer;

export const LoginFunction = ()=>{
  return (
      {
          type : 'LOGIN',
      }
  )
}




